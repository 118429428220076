import { Card, Empty, Typography } from "antd";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const { Meta } = Card;
function GridPdfViewer({ data, width = null, height = null, pageNumber }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [loadErrors, setLoadErrors] = useState([]);

  return (
    <>
      <>
        {data?.map((item, index) => (
          <Card key={index} style={{ cursor: "pointer" }}>
            <div
              style={{
                overflow: "hidden",
                height: height || "650px",
                width: width,
              }}
            >
              <Document
                file={item?.pdf || item?.url}
                onLoadError={() => {
                  const newLoadErrors = [...loadErrors];
                  newLoadErrors[index] = true;
                  setLoadErrors(newLoadErrors);
                }}
                error={
                  loadErrors[index] ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Empty description="Cannot load pdf file" />
                    </div>
                  ) : (
                    "Failed to load PDF"
                  )
                }
              >
                <Page
                  noData="Unable to read file"
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  size="A4"
                  scale={0.5}
                />
              </Document>
            </div>
            <Meta
              title={
                <Typography className="meta-title">{item?.path}</Typography>
              }
            />
          </Card>
        ))}
      </>
    </>
  );
}

export default GridPdfViewer;
