import React, { useState } from "react";
import { Table, Tag, Col, Pagination, Button, Space, Tooltip } from "antd";
import { statusColors } from "../../../helpers/medicineKeys";
import { EditOutlined, ExportOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter, timeAgo } from "../../../helpers/utility";
import InvestigationModal from "../../PatientsIP/InvestigationsIp/InvestigationModal";
import InvestigationGallery from "../../PatientsIP/InvestigationsIp/InvestigationGallery/InvestigationGallery";
import dayjs from "dayjs";

const InvestigationTable = ({ data, refreshPage, page, setPage, loading }) => {
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);

  const columns = [
    {
      title: "Investigation Name",
      dataIndex: "investigation_name",
      key: "investigation_name",
      render: (value, record) => (value ? value : record?.name),
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      render: (value) => capitalizeFirstLetter(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (value, record) => {
        return (
          <>
            <Tag color={statusColors?.[value]}>{value?.replace("_", " ")}</Tag>
            {value === "Report_Delivered" ? (
              <Tooltip
                title={dayjs(record?.delivery_on).format("DD MMM, YYYY")}
              >
                {timeAgo(record?.delivery_on)}
              </Tooltip>
            ) : value === "Sample_Collected" ? (
              <Tooltip
                title={dayjs(record?.delivery_on).format("DD MMM, YYYY")}
              >
                {timeAgo(record?.sample_collected_on)}
              </Tooltip>
            ) : value === "Ordered" ? (
              <Tooltip title={dayjs(record?.created_at).format("DD MMM, YYYY")}>
                {timeAgo(record?.created_at)}
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              setOpen(true);
              setEditData(record);
            }}
          >
            <EditOutlined />
          </Button>
          {record?.status === "Report_Delivered" ? (
            <ExportOutlined
              style={{ color: "green", fontSize: "18px" }}
              onClick={() => {
                setGalleryModal(true);
                setEditData(record);
              }}
            />
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.data?.filter((item) => !item?.is_initial)}
        columns={columns}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: 0 }}
      />
      {data?.data?.length > 50 && (
        <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={50}
            total={data?.total_pages}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </Col>
      )}

      {open && (
        <InvestigationModal
          refreshPage={refreshPage}
          open={open}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      {galleryModal && (
        <InvestigationGallery
          open={galleryModal}
          setOpen={setGalleryModal}
          refreshPage={refreshPage}
          editData={editData}
        />
      )}
    </>
  );
};

export default InvestigationTable;
