import { Descriptions, Image, Space } from "antd";
import React from "react";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import { specialCareOptions } from "../../Options/functionalAsessmentOptions";
import { PlusSquareOutlined } from "@ant-design/icons";
import SpecialCare from "../../../../assets/images/nurseIcons/specialcare.svg";

const NurseSpecialCareRequirementSummary = ({ formData }) => {
  const constructNurseSpecialCareSummaryItems = () => {
    const nurseSpecialCareSummaryItems = [];
    const special_care = formData?.special_care || {};

    if (getLabelTagsWithDescription(specialCareOptions, special_care)) {
      nurseSpecialCareSummaryItems.push({
        key: "1",
        label: "Area(s)",
        children: getLabelTagsWithDescription(specialCareOptions, special_care),
      });
    }
    return nurseSpecialCareSummaryItems;
  };
  const nurseSpecialCareSummaryItems = constructNurseSpecialCareSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={SpecialCare} preview={false} />
              <span>Area of Special Care Requirement</span>{" "}
            </Space>
          </span>
        }
        items={nurseSpecialCareSummaryItems}
        contentStyle={{ textAlign: "left", padding: "4px 4px" }}
      />
    </>
  );
};

export default NurseSpecialCareRequirementSummary;
