import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { PhysioInitialContext } from "./PhysiotherapistInitialAssessmentProvider";
import {
  physioInspectionAPI,
  updatePhysioInspectionAPI,
} from "../../../../services/physio.services";
import DoctorHistoryForm from "../../InspectionForms/HistoryForm/DoctorHistoryForm";
import PhysioFunctionalAssessment from "../../InspectionForms/FunctionalStatusAssessment/PhysioFunctionalAssessment";
import PhysioGeneralExaminationForm from "../../InspectionForms/Examination/GeneralExaminationForm/PhysioGeneralExaminationForm";
import PhysioSystemicExaminationForm from "../../InspectionForms/Examination/SystemicExaminationForm/PhysioSystemicExaminationForm";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import PhysioFunctionalDiagnosis from "../../InspectionForms/FunctionalDiagnosis/PhysioFunctionalDiagnosis";
import PhysioTherapeuticGoals from "../../InspectionForms/TherapeuticGoals/PhysioTherapeuticGoals";
import PhysioTherapy from "../../InspectionForms/Therapy/PhysioTherapy";
import PhysioTreatmentPlan from "../../InspectionForms/TreatmentPlan/PhysioTreatmentPlan";
import { LeftOutlined } from "@ant-design/icons";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";

const PhysiotherapistInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
  patientData,
  setConfirmModal,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [historyForm] = Form.useForm();
  const [generalForm] = Form.useForm();
  const [systemicForm] = Form.useForm();
  const [functionalForm] = Form.useForm();
  const [diagnosisForm] = Form.useForm();
  const [therapeuticForm] = Form.useForm();
  const [therapyForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(PhysioInitialContext);

  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await updatePhysioInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await physioInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success("Form submitted successfully");
      // const nextTab = (parseInt(activeTab) + 1).toString();
      // if (activeTab !== "5") {
      //   setActiveTab(nextTab);
      // } else if (activeTab === "5" && formData?.care_items_generated) {
      //   setVisible(false);
      // } else {
      //   setCurrent(1);
      // }
      // setIsDirty(false);
      if (activeTab === "8") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onHistorySubmit = (values) => {
    handleSubmit({ history: values, admission_id: params?.patient });
  };
  const onGeneralSubmit = (values) => {
    handleSubmit({
      general_examination: { ...values },
      admission_id: params?.patient,
    });
  };
  const onSystemicSubmit = (values) => {
    handleSubmit({
      systemic_examination: {
        ...values,
      },
      admission_id: params?.patient,
    });
  };
  const onFunctionalSubmit = (values) => {
    handleSubmit({
      functional_status: values,
      admission_id: params?.patient,
    });
  };
  const onDiagnosisSubmit = (values) => {
    handleSubmit({
      functional_diagnosis: values,
      admission_id: params?.patient,
    });
  };
  const onTherapeuticSubmit = (values) => {
    console.log("therapist form", values);
    handleSubmit({
      therapeutic_goals: values,
      admission_id: params?.patient,
    });
  };
  const onTherapySubmit = (values) => {
    handleSubmit({
      therapy: values,
      admission_id: params?.patient,
    });
  };
  const onTreatmentSubmit = (values) => {
    handleSubmit({
      treatment_plan: values,
      admission_id: params?.patient,
      intial_assessment_done: true,
    });
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return historyForm.submit();
      case "2":
        return generalForm.submit();
      case "3":
        return systemicForm.submit();
      case "4":
        return functionalForm.submit();
      case "5":
        return diagnosisForm.submit();
      case "6":
        return therapeuticForm.submit();
      case "7":
        return therapyForm.submit();
      case "8":
        return treatmentForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      // setNextTab(key);
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              historyForm.validateFields().then((values) => {
                setIsDirty(false);
                onHistorySubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              generalForm.validateFields().then((values) => {
                setIsDirty(false);
                onGeneralSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              systemicForm.validateFields().then((values) => {
                setIsDirty(false);
                onSystemicSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              functionalForm.validateFields().then((values) => {
                setIsDirty(false);
                onFunctionalSubmit(values);
                setActiveTab(key);
              });
              break;
            case "5":
              diagnosisForm.validateFields().then((values) => {
                setIsDirty(false);
                onDiagnosisSubmit(values);
                setActiveTab(key);
              });
              break;
            case "6":
              therapeuticForm.validateFields().then((values) => {
                setIsDirty(false);
                onTherapeuticSubmit(values);
                setActiveTab(key);
              });
              break;
            case "7":
              therapyForm.validateFields().then((values) => {
                setIsDirty(false);
                onTherapySubmit(values);
                setActiveTab(key);
              });
              break;
            case "8":
              treatmentForm.validateFields().then((values) => {
                setIsDirty(false);
                onTreatmentSubmit(values);
                setActiveTab(key);
              });
              break;
            default:
              setActiveTab(key);
          }
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Row>
        <Col span={4}>
          <Space>
            <LeftOutlined onClick={() => setConfirmModal(true)} />
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
            </Typography>
            {patientData?.admission?.bed?.bed_no && (
              <Tag color="orange">
                <Image src={bedImg} preview={false} />{" "}
                {patientData?.admission?.bed?.bed_no}
              </Tag>
            )}
          </Space>
          <br />
          <Space style={{ marginTop: "10px" }}>
            <Tag>
              {patientData?.admission?.patient?.age}{" "}
              {patientData?.admission?.patient?.gender}
            </Tag>
            <Tag>IP ID: {patientData?.admission?.id}</Tag>
          </Space>
        </Col>
        <Col span={20}>
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "History" },
              { key: "2", label: "General Examination" },
              { key: "3", label: "Systemic Examination" },
              { key: "4", label: "Functional Status Assessment" },
              { key: "5", label: "Functional Diagnosis" },
              { key: "6", label: "Therapeutic Goals" },
              { key: "7", label: "Therapy" },
              { key: "8", label: "Treatment Plan" },
            ]}
            onChange={handleTabChange}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0px" }} />
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div style={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
          {activeTab === "1" && (
            <Form
              id="history-form"
              form={historyForm}
              layout="vertical"
              onFinish={onHistorySubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <DoctorHistoryForm
                form={historyForm}
                context={PhysioInitialContext}
                editId={editId}
                patientData={patientData}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="general-form"
              form={generalForm}
              layout="vertical"
              onFinish={onGeneralSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioGeneralExaminationForm form={generalForm} />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="systemic-form"
              form={systemicForm}
              layout="vertical"
              onFinish={onSystemicSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioSystemicExaminationForm form={systemicForm} />
            </Form>
          )}
          {activeTab === "4" && (
            <Form
              id="functional-status-assessment"
              form={functionalForm}
              layout="vertical"
              onFinish={onFunctionalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioFunctionalAssessment form={functionalForm} />
            </Form>
          )}
          {activeTab === "5" && (
            <Form
              id="functional-diagnosis"
              form={diagnosisForm}
              layout="vertical"
              onFinish={onDiagnosisSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioFunctionalDiagnosis form={diagnosisForm} />
            </Form>
          )}
          {activeTab === "6" && (
            <Form
              id="therapeutic-goals"
              form={therapeuticForm}
              layout="vertical"
              onFinish={onTherapeuticSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioTherapeuticGoals form={therapeuticForm} />
            </Form>
          )}
          {activeTab === "7" && (
            <Form
              id="therapy"
              form={therapyForm}
              layout="vertical"
              onFinish={onTherapySubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioTherapy form={therapyForm} />
            </Form>
          )}
          {activeTab === "8" && (
            <Form
              id="treatment-plan"
              form={treatmentForm}
              layout="vertical"
              onFinish={onTreatmentSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PhysioTreatmentPlan form={treatmentForm} />
            </Form>
          )}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "8" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhysiotherapistInitialInspectionForm;
