import { Modal, Timeline } from "antd";
import { useEffect, useState } from "react";
import { getNurseReviewDataApi } from "../../../../services/nurse.services";

function NurseData({ nurseModalStatus, setNurseModalStatus, data, id }) {
  const [reviewData, setReviewData] = useState(null);

  useEffect(() => {
    if (id) {
      getNurseReviewDataApi(id)
        .then((res) => {
          setReviewData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);
  console.log(id);

  return (
    <Modal
      footer={false}
      title="Nurse Data"
      open={nurseModalStatus}
      onCancel={() => setNurseModalStatus(false)}
    >
      <Timeline
        items={[
          { children: "Create a services site 2015-09-01" },
          { children: "Solve initial network problems 2015-09-01" },
          { children: "Technical testing 2015-09-01" },
          { children: "Network problems being solved 2015-09-01" },
        ]}
      />
    </Modal>
  );
}

export default NurseData;
