import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Tag,
  theme,
  Row,
  Col,
  Space,
  Typography,
  Image,
  List,
  Segmented,
  Select,
  Skeleton,
} from "antd";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import filterIcon from "../../assets/images/nurseIcons/filter.svg";

import {
  floorListApi,
  getNurseIpPatientApi,
} from "../../services/nurse.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserContext";
import "./NurseView.css";
const { Option } = Select;
const { useToken } = theme;
const inPatientLabels = [
  { label: "Active", value: "current" },
  { label: "Discharged", value: "older" },
];

const defaultValue = [{ floor_no: "All", id: 0 }];
const NurseCards = () => {
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const { token } = useToken();
  const [activeSegment, setActiveSegment] = useState("current");
  const [ipData, setIpData] = useState([]);
  const [floor, setFloor] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const params =
      selectedFloor === 0
        ? `?facility=${
            Number(getCookie(FacilityID)) || userDetails?.currFacility
          }`
        : `?facility=${
            Number(getCookie(FacilityID)) || userDetails?.currFacility
          }&floor=${selectedFloor}`;
    getNurseIpPatientApi(params)
      .then((res) => {
        setIpData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [getCookie(FacilityID) || userDetails?.currFacility, selectedFloor]);

  useEffect(() => {
    floorListApi(
      `?facility=${Number(getCookie(FacilityID)) || userDetails?.currFacility}`
    )
      .then((res) => {
        setFloor(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [getCookie(FacilityID) || userDetails?.currFacility]);

  const onFloorChange = (value) => {
    setSelectedFloor(value);
  };
  return (
    <Row gutter={[6, 6]}>
      <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
        <Space>
          <Image src={filterIcon} preview={false} />
          <Select
            style={{ width: "300px" }}
            defaultValue={"All"}
            onChange={(value) => onFloorChange(value)}
          >
            {[...defaultValue, ...floor]?.map((item) => (
              <Option key={item?.id} value={item?.id}>
                {item?.floor_no === "All"
                  ? item?.floor_no
                  : `${item?.floor_no} - ${item?.building_name}`}
              </Option>
            ))}
          </Select>
          <Segmented
            style={{ backgroundColor: "#e6e6e6", padding: ".2rem" }}
            options={inPatientLabels}
            defaultValue={activeSegment}
          />
        </Space>
      </Col>
      <Col span={24}>
        {loading ? (
          <Skeleton active />
        ) : (
          <List
            className="card-shadow"
            itemLayout="vertical"
            bordered
            dataSource={ipData}
            style={{ background: "#fff" }}
            renderItem={(item) => (
              <List.Item key={item?.admission_id}>
                <Row>
                  <Col xs={24} sm={24} md={8} lg={6}>
                    <Row align="middle" style={{ width: "100%" }}>
                      <Col style={{ flex: "0 0 auto" }}>
                        <CustomAvatar
                          name={`${item?.patient_name}`}
                          indexId={item?.admission_id}
                          size="medium"
                        />
                      </Col>
                      <Col style={{ marginLeft: "8px", flex: "1 1 auto" }}>
                        <Row align="middle" style={{ gap: ".5rem" }}>
                          <Typography.Text strong>
                            {item?.patient_name}
                          </Typography.Text>
                          <Tag color="orange">
                            <Image src={bedImg} preview={false} /> {item?.bed}
                          </Tag>
                        </Row>
                        <Tag>IP ID: {item?.ip_id}</Tag>
                        <Tag>
                          {(item?.age || item?.gender) && (
                            <span>
                              {item?.age} {item?.gender === "Male" ? "M" : "F"}{" "}
                            </span>
                          )}
                        </Tag>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={16} lg={18}>
                    <Row>
                      <Col
                        className="dashboard-col-container"
                        xs={12}
                        sm={12}
                        md={8}
                        lg={4}
                        onClick={() =>
                          navigate(
                            `/nurse/home?patient=${item?.admission_id}&type=ia`
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Typography className="dashboard-card-text">
                            Initial Assessment
                          </Typography>

                          <Tag
                            className={
                              item?.nurse_initial_assessment
                                ? "tag-completed"
                                : "tag-pending"
                            }
                          >
                            {item?.nurse_initial_assessment
                              ? "Completed"
                              : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col
                        className="dashboard-col-container"
                        xs={12}
                        sm={12}
                        md={8}
                        lg={4}
                        onClick={() =>
                          navigate(
                            `/nurse/home?patient=${item?.admission_id}&type=diet`
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Typography className="dashboard-card-text">
                            Diet
                          </Typography>
                          <Tag
                            className={
                              item?.diet?.completed
                                ? "tag-completed"
                                : "tag-pending"
                            }
                          >
                            {item?.diet?.completed ? "Completed" : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col
                        className="dashboard-col-container"
                        xs={12}
                        sm={12}
                        md={8}
                        lg={5}
                        onClick={() =>
                          navigate(
                            `/nurse/home?patient=${item?.admission_id}&type=medicine`
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Typography className="dashboard-card-text">
                            Medicine
                          </Typography>
                          <Tag
                            className={
                              item?.medication?.completed
                                ? "tag-completed"
                                : "tag-pending"
                            }
                          >
                            {item?.medication?.completed
                              ? "Completed"
                              : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col
                        className="dashboard-col-container"
                        xs={12}
                        sm={12}
                        md={8}
                        lg={5}
                        onClick={() =>
                          navigate(
                            `/nurse/home?patient=${item?.admission_id}&type=monitoring`
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Typography className="dashboard-card-text">
                            Monitoring
                          </Typography>
                          <Tag
                            className={
                              item?.monitoring?.up_to_date
                                ? "tag-completed"
                                : "tag-pending"
                            }
                          >
                            {item?.monitoring?.up_to_date
                              ? "Completed"
                              : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col
                        className="dashboard-col-container"
                        xs={12}
                        sm={12}
                        md={8}
                        lg={6}
                        onClick={() =>
                          navigate(
                            `/nurse/home?patient=${item?.admission_id}&type=order-sheet`
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Typography className="dashboard-card-text">
                            Care Items
                          </Typography>
                          <Space>
                            <Tag className="tag-completed ">
                              Upcoming:
                              <span className="nurse-careitems-btn ">
                                {item?.care_items?.upcoming || 0}
                              </span>
                            </Tag>
                            <Tag className="tag-pending">
                              Missed:
                              <span className="nurse-careitems-btn">
                                {item?.care_items?.missed || 0}
                              </span>
                            </Tag>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        )}
      </Col>
    </Row>
  );
};

export default NurseCards;
