import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Space,
  Input,
  Radio,
  InputNumber,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import ServiceDetails from "./ServiceDetails";
import InvestigationDetails from "./investigationDetails";
import RoomDetails from "./RoomDetails";
import {
  createPackage,
  updatePackage,
} from "../../../../services/service.services";
import { formatToINR } from "../../../../helpers/utility";

const PackagesForm = ({ visible, setVisible, refreshPackages, editData }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [duration, setDuration] = useState(null);

  const onClose = () => {
    setVisible(false);
  };

  const formatPrice = (price) => {
    if (typeof price === "number") {
      return price.toFixed(2);
    } else if (typeof price === "string" && !isNaN(parseFloat(price))) {
      return parseFloat(price).toFixed(2);
    }
    return price;
  };

  useEffect(() => {
    if (editData?.id) {
      const investigations = editData.package_investigations.map((item) => ({
        investigation: item?.investigation?.id,
        investigation_name: item?.investigation?.name,
        price: item?.price,
        id: item?.id,
        is_percentage: item?.is_percentage,
        discount:
          item?.is_percentage === true ? item?.discount * 100 : item?.discount,
      }));
      const services = editData.package_services.map((item) => ({
        service: item?.service?.id,
        service_name: item?.service?.name,
        price: item?.price,
        id: item?.id,
        quantity: item?.quantity,
        totalservice_price: item?.quantity * item?.price,
        is_percentage: item?.is_percentage,
        discount:
          item?.is_percentage === true ? item?.discount * 100 : item?.discount,
      }));
      form.setFieldsValue({
        ...editData,
        investigations: investigations,
        room_discount:
          editData?.room_is_percentage === true
            ? editData?.room_discount * 100
            : editData?.room_discount,
        services: services,
      });
      setTotalPrice(editData?.total_price);
      setDuration(editData?.duration);
    }
  }, [editData?.id]);

  const onFinish = (values) => {
    setBtnLoading(true);
    const { investigations, services, ...rest } = values;
    const transformedInvestigations = investigations.map((inv) => {
      const { discount, is_percentage, investigation, price, id } = inv;
      return {
        investigation: investigation,
        ...(id && { id: id }),
        price: price,
        discount: is_percentage ? (discount * price) / 100 : discount,
        is_percentage: is_percentage ? is_percentage : false,
      };
    });
    const transformedServices = services.map((ser) => {
      const { discount, is_percentage, service, price, quantity, id } = ser;
      return {
        service: service,
        price: price,
        ...(id && { id: id }),
        discount: is_percentage
          ? (discount * price * quantity) / 100
          : discount,
        quantity: quantity,
        is_percentage: is_percentage ? is_percentage : false,
      };
    });
    const finalValues = {
      ...rest,
      room_discount: values?.room_is_percentage
        ? (values?.room_discount * values?.room_price) / 100
        : values?.room_discount,
      package_investigations: transformedInvestigations,
      package_services: transformedServices,
      total_price: formatPrice(totalPrice),
    };

    if (editData?.id) {
      updatePackage(editData?.id, finalValues)
        .then((res) => {
          message.success(res?.data?.message);
          setVisible(false);
          setBtnLoading(false);
          refreshPackages();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    } else {
      createPackage(finalValues)
        .then((res) => {
          message.success(res?.data?.message);
          setVisible(false);
          setBtnLoading(false);
          refreshPackages();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    }
  };

  const calculateTotalPrice = (values) => {
    let total = 0;
    if (values?.room_price) {
      const price = values?.room_price || 0;
      const discount = values?.room_discount || 0;
      const is_percentage = values?.room_is_percentage;
      total += is_percentage
        ? price - (discount * price) / 100
        : price - discount;
    }
    if (values?.services) {
      values?.services?.forEach((service) => {
        const price = service?.price || 0;
        const discount = service?.discount || 0;
        const quantity = service?.quantity || 1;
        const is_percentage = service?.is_percentage;

        if (service) {
          service.totalservice_price =
            service?.price * service?.quantity || service?.price;
        }
        total += is_percentage
          ? price * quantity - (discount * price * quantity) / 100
          : price * quantity - discount;
      });
    }

    if (values?.investigations) {
      values?.investigations?.forEach((investigation) => {
        const price = investigation?.price || 0;
        const discount = investigation?.discount || 0;
        const is_percentage = investigation?.is_percentage;
        total += is_percentage
          ? price - (discount * price) / 100
          : price - discount;
      });
    }

    setTotalPrice(total);
    return total;
  };

  const onValuesChange = (changedValues, allValues) => {
    const totalPrice = calculateTotalPrice({ ...changedValues, ...allValues });
    setFormValues({ ...changedValues, ...allValues });
    form.setFieldsValue({ total_price: totalPrice });
  };

  return (
    <>
      <Drawer
        width={"80%"}
        open={visible}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                Add Package
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={onClose} />
            </Col>
          </Row>
        }
        footer={
          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                loading={btnLoading}
                className={btnStyles.lgBtn}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        }
        onClose={onClose}
        closeIcon={null}
      >
        <Form
          ref={formRef}
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          // requiredMark={false}
          onValuesChange={onValuesChange}
          initialValues={{
            services: [{ is_percentage: false }],
            investigations: [{ is_percentage: false }],
            room_is_percentage: false,
          }}
        >
          <Space>
            <Form.Item
              label="Package Name"
              name="name"
              rules={[{ required: true, message: "Enter package name" }]}
            >
              <Input
                placeholder="Enter package name"
                className={inputStyles.mdInput}
                style={{ width: "200px" }}
              />
            </Form.Item>
            <Form.Item label="Package Details" name="description">
              <Input.TextArea
                placeholder="Enter package details"
                className={inputStyles.mdInput}
                style={{ width: "400px" }}
                rows={1}
              />
            </Form.Item>
          </Space>
          <RoomDetails form={form} />
          <ServiceDetails
            form={form}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
          />
          <InvestigationDetails
            form={form}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            editData={editData}
          />
          <Typography
            style={{
              fontSize: "20px",
              fontWeignt: 500,
              color: "#000000",
              background: "#F0ECFE",
              padding: "8px",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          >
            Additinal Information
          </Typography>
          <Space>
            <Form.Item label="Duration" name="duration">
              <Radio.Group onChange={(e) => setDuration(e.target.value)}>
                <Radio value="1_Day">1 Day</Radio>
                <Radio value="1_Week">1 Week</Radio>
                <Radio value="3_Months">3 Month</Radio>
                <Radio value="others">Other</Radio>
              </Radio.Group>
            </Form.Item>
            {duration === "others" && (
              <Form.Item label="Enter No.of Days" name="no_of_days">
                <InputNumber
                  className={inputStyles.mdNumberInput}
                  style={{ width: "200px" }}
                />
              </Form.Item>
            )}
          </Space>
          <div className="flex-end">
            <Typography>
              Total Price: {formatPrice(formatToINR(totalPrice))}
            </Typography>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default PackagesForm;
