import React, { useState } from "react";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import VitalsModal from "./VitalsModal";
import dayjs from "dayjs";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import VitalsIcon from "../../../../assets/images/nurseIcons/heart-rate.svg";

const NurseMonitoring = ({ data, refresh }) => {
  const [open, setOpen] = useState(false);
  const [editingKey, setEditingKey] = useState(null);

  const columns = [
    {
      title: "Time",
      dataIndex: "action_time",
      width: "10%",
      render: (value) => dayjs(value)?.format("MMM DD, hh:mm A"),
    },
    {
      title: "Temperature",
      dataIndex: "temperature",
      width: "10%",
    },
    {
      title: "Pulse Rate",
      dataIndex: "pulse",
      width: "10%",
    },
    {
      title: "Respiratory Rate",
      dataIndex: "respiration",
      width: "10%",
    },
    {
      title: "SPO2",
      dataIndex: "oxygen_saturation",
      width: "10%",
    },
    {
      title: "Systolic Pressure",
      dataIndex: "systolic_pressure",
      width: "10%",
    },
    {
      title: "Diastolic Pressure",
      dataIndex: "diastolic_pressure",
      width: "10%",
    },
    {
      title: "Action",
      width: "10%",
      render: (_, record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                setOpen(true);
                setEditingKey(record);
              }}
              style={{ color: "#7f56d9", cursor: "pointer" }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {data?.length > 0 ? (
        <>
          <Table
            className="ant-table-container ant-table-header"
            scroll={{ y: "calc(100vh - 360px)", x: "400px" }}
            bordered
            dataSource={data}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
          {open && (
            <VitalsModal
              open={open}
              setOpen={setOpen}
              refresh={refresh}
              editData={editingKey}
            />
          )}
        </>
      ) : (
        <NoDataPage
          title={"No Vital Signs Data Available"}
          subtitle={
            "Please check back later or ensure all relevant data has been recorded."
          }
          image={VitalsIcon}
        />
      )}
    </>
  );
};

export default NurseMonitoring;
