import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  List,
  Slider,
  Input,
  Button,
  message,
  DatePicker,
  Tag,
  Image,
} from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  getNurseCommonData,
  updateNurseCommonData,
} from "../../../services/nurse.services";
import useQuery from "../../../hooks/useQuery";
import dayjs from "dayjs";
import { convertUtcToIst } from "../../../helpers/utility";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { dietTag } from "../../../helpers/medicineKeys";
import "./DietChart.css";
import NoDataPage from "../../NoDataPages/NoDataPage";
import DietImage from "../../../assets/images/nurseIcons/diet.svg";
import calenderIcon from "../../../assets/images/nurseIcons/calender.svg";

const dateFormat = "YYYY-MM-DD";

const DietChart = () => {
  const curDate = dayjs().format(dateFormat);
  const params = useQuery();
  const [notes, setNotes] = useState({});
  const [sliders, setSliders] = useState({});
  const [dietData, setDietData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [date, setDate] = useState(curDate);
  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }
  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };

  useEffect(() => {
    setLoading(true);
    getNurseCommonData(params?.patient, "diet", date)
      .then((res) => {
        const processedData = res?.data?.data?.map((item) => ({
          ...item,
          sliderValue:
            item?.action === "nothing"
              ? 0
              : item?.action === "one_quarter"
              ? 0.25
              : item?.action === "half"
              ? 0.5
              : item?.action === "three_quarters"
              ? 0.75
              : item?.action === "full"
              ? 1
              : 0,
          notes: item.notes || "",
          editable: item?.processed ? false : true,
        }));
        setDietData(processedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh, date]);

  const handleNoteChange = (id, value) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [id]: value,
    }));
  };

  const handleSliderChange = (id, value) => {
    setSliders((prevSliders) => ({
      ...prevSliders,
      [id]: value,
    }));
  };

  const handleSubmit = (item) => {
    const data = {
      patient: params.patient,
      remarks: notes[item.id],
      action:
        sliders[item.id] === 0
          ? "nothing"
          : sliders[item.id] === 0.25
          ? "one_quarter"
          : sliders[item.id] === 0.5
          ? "half"
          : sliders[item.id] === 0.75
          ? "three_quarters"
          : sliders[item.id] === 1
          ? "full"
          : "nothing",
    };

    updateNurseCommonData(item.id, data)
      .then((res) => {
        message.success(res.data.message);
        refreshPage();
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const toggleEdit = (item) => {
    setDietData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.id === item.id ? { ...dataItem, editable: true } : dataItem
      )
    );
  };

  const tipFormatter = (value) => {
    switch (value) {
      case 0:
        return "Nothing";
      case 0.25:
        return "1/4";
      case 0.5:
        return "1/2";
      case 0.75:
        return "3/4";
      case 1:
        return "Full";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="nurse-cards-container">
        <div className="flex-end" style={{ marginBottom: "8px" }}>
          <DatePicker
            style={{ border: "1px solid #a182e3", width: "12%" }}
            disabledDate={disabledDate}
            className={inputStyles.mdDateInput}
            onChange={onDateChange}
            defaultValue={dayjs(curDate)}
            allowClear={false}
            suffixIcon={<Image width={20} src={calenderIcon} preview={false} />}
          />
        </div>
        {dietData.length > 0 ? (
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <List
                style={{ background: "#fff" }}
                loading={loading}
                header={<Typography>Diet Chart</Typography>}
                itemLayout="vertical"
                bordered
                dataSource={dietData}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <Row gutter={[12, 12]}>
                      <Col xs={24} sm={6} md={4} lg={4}>
                        <Space>
                          <ClockCircleOutlined />
                          {item.action_time &&
                            convertUtcToIst(item.action_time, false)}
                        </Space>
                      </Col>

                      <Col xs={12} sm={12} md={6} lg={6}>
                        <Space direction="vertical">
                          <Typography.Text>{item.data.name}</Typography.Text>
                          {item?.data?.instructions && (
                            <Tag color="yellow">
                              <Typography
                                style={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {item?.data?.instructions}
                              </Typography>
                            </Tag>
                          )}
                        </Space>
                      </Col>
                      <Col xs={12} sm={12} md={7} lg={6}>
                        {!item?.editable ? (
                          <>{dietTag(item?.action)}</>
                        ) : (
                          curDate === date && (
                            <Slider
                              marks={{ 0: "Nothing", 1: "Full" }}
                              step={0.25}
                              max={1}
                              min={0}
                              dots={true}
                              style={{ width: "90%" }}
                              onChange={(value) =>
                                handleSliderChange(item.id, value)
                              }
                              value={
                                sliders[item.id] !== undefined
                                  ? sliders[item.id]
                                  : item.sliderValue
                              }
                              styles={{
                                track: {
                                  background: "transparent",
                                },
                                tracks: {
                                  background: "green",
                                },
                              }}
                              tipFormatter={tipFormatter}
                            />
                          )
                        )}
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={6}>
                        {!item.editable ? (
                          <Typography>
                            {item.remarks ? item.remarks : "-"}
                          </Typography>
                        ) : (
                          curDate === date && (
                            <Input.TextArea
                              placeholder="Notes"
                              value={notes[item.id] || item.remarks}
                              onChange={(e) =>
                                handleNoteChange(item.id, e.target.value)
                              }
                            />
                          )
                        )}
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2}>
                        {item.editable ? (
                          <Button
                            onClick={() => handleSubmit(item)}
                            type="primary"
                            ghost
                          >
                            Submit
                          </Button>
                        ) : (
                          curDate === date && (
                            <Button
                              onClick={() => toggleEdit(item)}
                              type="primary"
                              ghost
                            >
                              Edit
                            </Button>
                          )
                        )}
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        ) : (
          <NoDataPage
            title="No Diet Found"
            subtitle="It looks like there are no diet at the moment."
            image={DietImage}
          />
        )}{" "}
      </div>
    </>
  );
};

export default DietChart;
