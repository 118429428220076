import React, { useState } from "react";
import { Button, Space, Empty, Image, Typography } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const UploadsView = ({ selectedGroup, width = null, height = null }) => {
  const data = selectedGroup?.items;
  const [pageNumbers, setPageNumbers] = useState([]);
  const [numPages, setNumPages] = useState([]);
  const [loadErrors, setLoadErrors] = useState([]);

  const settings = {
    dots: data.length > 1,
    infinite: data.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: data.length > 1,
  };

  const onDocumentLoadSuccess = (pdf, index) => {
    const newNumPages = [...numPages];
    newNumPages[index] = pdf.numPages;
    setNumPages(newNumPages);
    const newPageNumbers = [...pageNumbers];
    newPageNumbers[index] = 1;
    setPageNumbers(newPageNumbers);
  };

  const goToPrevPage = (index) => {
    const newPageNumbers = [...pageNumbers];
    newPageNumbers[index] =
      pageNumbers[index] - 1 <= 1 ? 1 : pageNumbers[index] - 1;
    setPageNumbers(newPageNumbers);
  };

  const goToNextPage = (index) => {
    const newPageNumbers = [...pageNumbers];
    newPageNumbers[index] =
      pageNumbers[index] + 1 >= numPages[index]
        ? numPages[index]
        : pageNumbers[index] + 1;
    setPageNumbers(newPageNumbers);
  };

  return (
    <>
      <Slider {...settings}>
        {data?.map((item, index) => (
          <div key={index}>
            <Typography className="inv-slider-filename">
              {item.name} {item.path}
            </Typography>
            {item.type === "image" ? (
              <div className="inv-slider-img-container">
                <Image
                  src={item.url}
                  className="inv-slider-img "
                  preview={false}
                />
              </div>
            ) : (
              <div style={{ overflow: "auto", height: height || "700px" }}>
                <Document
                  file={item.url}
                  onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf, index)}
                  onLoadError={() => {
                    const newLoadErrors = [...loadErrors];
                    newLoadErrors[index] = true;
                    setLoadErrors(newLoadErrors);
                  }}
                  error={
                    loadErrors[index] ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Empty description="Cannot load pdf file" />
                      </div>
                    ) : (
                      "Failed to load PDF"
                    )
                  }
                >
                  <Page
                    noData="Unable to read file"
                    pageNumber={pageNumbers[index]}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    size="A4"
                    scale={1.5}
                  />
                </Document>
                {!loadErrors[index] && numPages[index] > 1 && (
                  <Space
                    align="end"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end !important",
                    }}
                  >
                    <Button
                      onClick={() => goToPrevPage(index)}
                      disabled={pageNumbers[index] <= 1}
                    >
                      Prev
                    </Button>
                    <Button
                      onClick={() => goToNextPage(index)}
                      disabled={numPages[index] === pageNumbers[index]}
                    >
                      Next
                    </Button>
                  </Space>
                )}
              </div>
            )}
          </div>
        ))}
      </Slider>
    </>
  );
};

export default UploadsView;
