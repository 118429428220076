import React, { useState, useEffect } from "react";
import { Table, Form, Select, Input } from "antd";
import inputStyles from "../../../../../styles/Custom/Input.module.css";
import { getDietApi } from "../../../../../services/diet.services";

const DietEditTable = ({ type, data, form, onChange }) => {
  const [items, setItems] = useState([]);
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    getDietApi(`patination=no_pagination`)
      .then((res) => setItems(res?.data?.data || []))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Set initial form values based on data
    tableData?.forEach((record) => {
      form.setFieldsValue({
        [record.time]: {
          [type]: {
            items: record?.items,
            comments: record?.comments || "",
          },
        },
      });
    });
  }, [tableData, form, type]);

  const handleItemChange = (selectedItems, record) => {
    const totalValues = selectedItems.reduce(
      (totals, itemName) => {
        const item = items?.find((dietItem) => dietItem?.id === itemName);
        if (item) {
          totals.proteins += item?.protien || 0;
          totals.calories += item?.calories || 0;
          totals.sodium += item?.sodium || 0;
          totals.potassium += item?.potassium || 0;
          totals.carbohydrates += item?.carbohydrates || 0;
        }
        return totals;
      },
      { proteins: 0, calories: 0, sodium: 0, potassium: 0, carbohydrates: 0 }
    );

    // Fetch the current comment for the record time
    const currentComments = form.getFieldValue([record.time, type, "comments"]);

    const updatedData = tableData?.map((data) =>
      data.time === record.time
        ? {
            ...data,
            items: selectedItems,
            comments: currentComments || record?.comments, // Use existing comment if available
            ...totalValues,
            modified: true,
          }
        : data
    );

    // Update the form with the newly selected items and comments
    form.setFieldsValue({
      [record.time]: {
        [type]: {
          items: selectedItems,
          comments: currentComments || record?.comments, // Set comments here as well
        },
      },
    });

    setTableData(updatedData);
    onChange(updatedData); // Notify parent component of the change
  };

  const columns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 80,
      render: (text) => (
        <span>
          {text >= 12
            ? `${text > 12 ? text - 12 : text}:00 PM`
            : `${text}:00 AM`}
        </span>
      ),
      fixed: "left",
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 300,
      render: (text, record) => (
        <Form.Item name={[record.time, type, "items"]}>
          <Select
            className={inputStyles.mdSelect}
            style={{ width: "100%" }}
            mode="multiple"
            onChange={(selectedItems) =>
              handleItemChange(selectedItems, record)
            }
          >
            {items.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Proteins",
      dataIndex: "proteins",
      key: "proteins",
      width: 80,
    },
    {
      title: "Calories",
      dataIndex: "calories",
      key: "calories",
      width: 80,
    },
    {
      title: "Sodium",
      dataIndex: "sodium",
      key: "sodium",
      width: 80,
    },
    {
      title: "Potassium",
      dataIndex: "potassium",
      key: "potassium",
      width: 80,
    },
    {
      title: "Carbohydrates",
      dataIndex: "carbohydrates",
      key: "carbohydrates",
      width: 80,
    },
    {
      title: "Comment",
      dataIndex: "comments",
      key: "comments",
      width: 80,
      render: (_, record) => (
        <Form.Item name={[record.time, type, "comments"]}>
          <Input.TextArea className={inputStyles.mdInput} rows={1} />
        </Form.Item>
      ),
    },
  ];

  return <Table dataSource={tableData} columns={columns} pagination={false} />;
};

export default DietEditTable;
