import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import CustomLayout from "../Layout/CustomLayout";
import { Typography, Image } from "antd";
import ReceptionistSideMenu from "../Layout/CustomLayout/ReceptionistSideMenu";
import AllPatient from "./AllPatient/AllPatient";
import textStyles from "../../styles/Custom/Typography.module.css";
import Inpatients from "./Inpatients/Inpatients";
import Appointments from "./Appointments/Appointments";
import { getPatientListApi } from "../../services/receptionist.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserContext";
import Investigations from "./Investigations/Investigations";
import useQuery from "../../hooks/useQuery";
import PatientsIP from "../PatientsIP/PatientsIP";
import Billing from "./Billing/Billing";
import BillingPage from "./Billing/BillingPage";

const Receptionist = () => {
  const query = useParams();
  const params = useQuery();
  const { userDetails } = useContext(UserContext);
  const [patientData, setPatientData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    setLoading(true);
    let queryParams = {
      page: page,
      limit: 50,
      facility_id: Number(getCookie(FacilityID)) || userDetails?.currFacility,
    };
    if (searchQuery) {
      queryParams.search = searchQuery;
    }
    const queryString = new URLSearchParams(queryParams);
    getPatientListApi(queryString)
      .then((res) => {
        setLoading(false);
        setPatientData(res?.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
    refresh,
    searchQuery,
    page,
    query?.tab,
  ]);

  const renderContent = () => {
    switch (query?.tab) {
      case "home":
        return params?.patient ? (
          <>
            <PatientsIP />
          </>
        ) : (
          <AllPatient
            data={patientData}
            refresh={refreshPage}
            searchQuery={searchQuery}
            page={page}
            setPage={setPage}
            setSearchQuery={setSearchQuery}
            loading={loading}
          />
        );
      // case "in-patients":
      //   return <Inpatients refresh={refreshPage} />;
      case "appointments":
        return <Appointments />;
      case "investigation":
        return <Investigations />;
      default:
        return query?.tab === "ip" ? (
          <PatientsIP />
        ) : params?.patient ? (
          <BillingPage />
        ) : (
          <Billing />
        );
    }
  };
  return (
    <>
      {query?.tab === "in-patients" ? (
        <Inpatients refresh={refreshPage} />
      ) : (
        <>
          {params?.patient ? (
            <CustomLayout>{renderContent()}</CustomLayout>
          ) : (
            <CustomLayout
              sideContent={<ReceptionistSideMenu refresh={refreshPage} />}
              header={
                <Typography className={textStyles.h6}>
                  {query?.tab === "home"
                    ? "All Patients"
                    : query?.tab === "in-patients"
                    ? "In Patients"
                    : query?.tab === "billing"
                    ? "Billing"
                    : query?.tab === "appointments"
                    ? "Appointments"
                    : query?.tab === "investigation" && "Investigations"}
                </Typography>
              }
            >
              {renderContent()}
            </CustomLayout>
          )}
        </>
      )}
    </>
  );
};

export default Receptionist;
