import { Button, Form, Typography, Input, message, Card } from "antd";
import React, { useEffect, useState } from "react";

import ReflexesForm from "../SystemicExaminationForm/CNSForm/ReflexesForm";
import {
  getReflexesAPI,
  reflexesAPI,
  updateReflexesAPI,
} from "../../../../../services/physio.services";

const Reflexes = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateReflexesAPI(scalesId?.reflexes, finalData);
      } else {
        const response = await reflexesAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, reflexes: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.reflexes) {
      getReflexesAPI(admission, scalesId?.reflexes)
        .then((response) => {
          form.setFieldsValue(response.data);
          setFormData(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Card style={{ width: "800px" }}>
          <Form
            onFinish={(values) => handleSubmitWithAction(values, "continue")}
            form={form}
            layout="vertical"
          >
            <ReflexesForm />

            <Form.Item name="comments" className="scales-comments-formitem">
              <Input.TextArea rows={4} placeholder="Comments" />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default Reflexes;
