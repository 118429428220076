import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Col,
  List,
  Pagination,
  Row,
  Segmented,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import dayjs from "dayjs";
import tagStyles from "../../styles/Custom/Tag.module.css";
import { useNavigate } from "react-router-dom";
import { getOutPatientListApi } from "../../services/patient.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import "./PatientList.css";
const { useToken } = theme;
const OutPatientsCard = ({ userDetails, role }) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [activeOutSegment, setActiveOutSegment] = useState("today");
  const [outPatientList, setOutPatientList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const outPatientLabels = [
    { label: "Today", value: "today" },
    { label: "Week", value: "week" },
  ];
  const onOutPatientSegmentChange = (value) => {
    setActiveOutSegment(value);
  };
  useEffect(() => {
    getOutPatientListApi(
      `facility=${Number(getCookie(FacilityID)) || userDetails?.currFacility}`,
      activeOutSegment,
      pageNo
    )
      .then((res) => {
        setOutPatientList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    getCookie(FacilityID) || userDetails?.currFacility,
    activeOutSegment,
    pageNo,
  ]);
  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 16, offset: 4 }}
      >
        <List
          style={{ background: "#fff" }}
          className="patient-list"
          // pagination={{
          //   onChange: (page) => {
          //     console.log(page);
          //     setPageNo(page);
          //   },
          //   pageSize: 20,
          // }}
          pagination={false}
          bordered
          header={
            <Row>
              <Col span={12}>Out Patients</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Segmented
                  options={outPatientLabels}
                  defaultValue={activeOutSegment}
                  onChange={onOutPatientSegmentChange}
                />
              </Col>
            </Row>
          }
          itemLayout="horizontal"
          size="small"
          dataSource={outPatientList?.data}
          renderItem={(item) => (
            <List.Item
              key={item?.title}
              extra={
                <Typography.Link
                  style={{ color: token.colorPrimary }}
                  onClick={() =>
                    navigate(`/${role}/home?patient=${item?.id}&type=op`)
                  }
                >
                  Start Consultation <ArrowRightOutlined />
                </Typography.Link>
              }
            >
              <Col span={6}>
                <List.Item.Meta
                  avatar={
                    <CustomAvatar
                      name={`${item?.patient_name}`}
                      indexId={item?.id}
                      size="medium"
                    />
                  }
                  title={
                    <Space size="small">
                      <Typography>
                        {item?.patient_first_name} {item?.patient_last_name}
                      </Typography>
                    </Space>
                  }
                />
              </Col>
              <Tag>IP ID:{item?.id}</Tag>

              {(item?.patient_age || item?.patient_gender) && (
                <Tag color="blue">
                  {item?.patient_gender} {item?.patient_age}
                </Tag>
              )}
              <Tag className={tagStyles.smTag}>
                Date:{" "}
                {item?.appointment_date &&
                  dayjs(item?.appointment_date).format("MMM DD")}
              </Tag>
              <Tag>
                Doctor: {item?.doctor_first_name} {item?.doctor_last_name}
              </Tag>
            </List.Item>
          )}
        />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 16, offset: 4 }}
        // xl={16}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Pagination
          current={pageNo}
          onChange={(value) => setPageNo(value)}
          pageSize={50}
          total={outPatientList?.total_pages * 50}
          showSizeChanger={false}
        />
      </Col>
    </Row>
  );
};

export default OutPatientsCard;
