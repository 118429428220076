import {
  Col,
  Row,
  Tag,
  Form,
  List,
  Space,
  Avatar,
  Button,
  Select,
  message,
  Skeleton,
  Popconfirm,
  Image,
} from "antd";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";

import AddNotes from "./AddNotes";
import useTwitterTimestamp from "../../../hooks/useTwitterTimestamp";
import {
  getPatientMonitoringAPI,
  deletePatientMonitoringAPI,
} from "../../../services/patient.services";
import CustomAvatar from "../../../blocks/Avatars/CustomAvatar";
import filterIcon from "../../../assets/images/nurseIcons/filter.svg";
import NoDataPage from "../../NoDataPages/NoDataPage";
import ProgressNotesIcon from "../../../assets/images/nurseIcons/notes.svg";

function ProgressMonitoring({ patient }) {
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("all");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    type: "add",
    id: 0,
  });

  async function getData() {
    setLoading(true);
    const params = { role: selectedRole };
    if (selectedRole === "all") {
      delete params.role;
    }
    try {
      const res = await getPatientMonitoringAPI(patient, params);

      setData(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedRole) getData();
  }, [selectedRole]);

  function updateItem(item) {
    form.setFieldsValue(item);
    setIsModalOpen({ status: true, type: "update", id: item?.id });
  }

  function addItem() {
    form.resetFields(["notes"]);
    setIsModalOpen({ status: true, type: "add" });
  }

  async function deleteItem(id) {
    try {
      const res = await deletePatientMonitoringAPI(patient, id);
      message.success(res.data.message);
      getData();
    } catch (error) {
      message.success(error.response.data.message);
    }
  }

  const options = [
    { label: "All", value: "all" },
    { label: "Doctor", value: "Doctor" },
    { label: "Nurse", value: "Nurse" },
    { label: "Physiotherapist", value: "Physiotherapist" },
    { label: "Speech Swallow Therapist", value: "Speech_Swallow_Therapist" },
    { label: "Respiratory Therapist", value: "Respiratory_Therapist" },
    { label: "Nutritionist", value: "Nutritionist" },
    { label: "Receptionist", value: "Receptionist" },
  ];

  function RenderItem(item, index) {
    const timestamp = useTwitterTimestamp(item?.updated_at);
    return (
      <List.Item
        actions={[
          <Button type="secondary" onClick={() => updateItem(item)}>
            <EditOutlined style={{ color: "blue", fontSize: "14px" }} />
          </Button>,
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this record?"
            onConfirm={async () => await deleteItem(item?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="secondary">
              <DeleteOutlined style={{ color: "red", fontSize: "14px" }} />
            </Button>
          </Popconfirm>,
        ]}
      >
        <Skeleton avatar title={false} loading={loading} active>
          <List.Item.Meta
            avatar={
              <CustomAvatar
                name={`${item?.done_by_first_name} ${item?.done_by_last_name}`}
                indexId={item?.id}
                size="medium"
              />
            }
            title={
              <Space>
                <span>
                  {item?.done_by_first_name} {item?.done_by_last_name}
                </span>
                <Tag color="geekblue">{timestamp}</Tag>
              </Space>
            }
            description={item?.notes}
          />
        </Skeleton>
      </List.Item>
    );
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={21} style={{ display: "flex", justifyContent: "end" }}>
        <Space>
          <Image src={filterIcon} preview={false} />
          <Select
            options={options}
            onChange={setSelectedRole}
            defaultValue={selectedRole}
            style={{ minWidth: "230px" }}
          />
          <Button type="primary" onClick={addItem}>
            Add Notes
          </Button>
        </Space>
        {isModalOpen?.status && (
          <AddNotes
            form={form}
            patient={patient}
            getData={getData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </Col>
      <Col lg={{ span: 18, offset: 3 }} md={{ span: 24 }}>
        {data?.length > 0 ? (
          <List
            bordered
            dataSource={data}
            itemLayout="horizontal"
            renderItem={RenderItem}
            style={{ backgroundColor: "#fffff" }}
          />
        ) : (
          <NoDataPage
            title={"Currently, No Progress Notes Have Been Added"}
            subtitle={"Please add new notes or check back later."}
            image={ProgressNotesIcon}
          />
        )}
      </Col>
    </Row>
  );
}

export default ProgressMonitoring;
