import { Card, Checkbox, Form, Radio, Row, Col, Typography } from "antd";
import React from "react";
import { attentionCheckboxOptions } from "../../../../Options/physioOptions";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Attention = () => {
  const attentionFields = [
    {
      label: "Repeat the following in forward order",
      sequence: "2 1 8 5 4",
      value: "attention_1",
    },
    {
      label: "Repeat the following in backward order",
      sequence: "7 4 2",
      value: "attention_2",
    },
    {
      label: "Repeat the list and tap hand on occurrence of A",
      sequence: "F B A C M N A A J K L B A F A K D E A A A J A M O F A A B",
      value: "attention_3",
    },
  ];

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={{ width: "80%" }}
      gutter={[16, 16]}
    >
      {attentionFields.map((field) => (
        <Col span={12}>
          <Card key={field.value}>
            <Typography style={{ textAlign: "center" }}>
              <Typography.Text>{field.label}</Typography.Text>
              <br />
              <br />
              <Typography.Text style={{ fontSize: "18px" }} strong>
                {field.sequence}
              </Typography.Text>
            </Typography>
            <br />
            <Form.Item name={["attention_data", field.value]}>
              <Row justify="center">
                <Col>
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="center" gutter={[8, 0]}>
                      <Col>
                        <Radio.Button value={"yes"} className="like-btn">
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button value={"no"} className="dislike-btn">
                          <DislikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col>
              </Row>
            </Form.Item>
          </Card>
        </Col>
      ))}
      <Col span={12}>
        <Card key="attention_4" style={{ marginBottom: 16 }}>
          <Typography style={{ textAlign: "center" }}>
            <Typography.Text strong>
              Serial 7 subtraction starting from 100
            </Typography.Text>
            <br />
            <Typography.Text strong></Typography.Text>
          </Typography>
          <Row justify="center">
            <Col>
              <Form.Item name={["attention_data", "attention_4"]}>
                <Checkbox.Group options={attentionCheckboxOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Attention;
