import React, { useRef, useState } from "react";
import { Collapse, Form, Button, message, Space, Card } from "antd";
import DietTable from "./DietTable";
import {
  afternoonKeys,
  customTag,
  eveningKeys,
  morningKeys,
  transformData,
} from "./nutritionistKeys";
import { addPatientDietItemsApi } from "../../../../services/nutritionist.services";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import useQuery from "../../../../hooks/useQuery";

const { Panel } = Collapse;

const NurtritionistDietChart = ({ refresh }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [dietData, setDietData] = useState({
    morning: [],
    afternoon: [],
    evening: [],
  });

  // Handle changes to the diet tables
  const handleTableChange = (type, updatedData) => {
    setDietData((prevData) => ({
      ...prevData,
      [type]: updatedData,
    }));
  };

  // Submit the form data
  const onFinish = (values) => {
    addPatientDietItemsApi(params?.patient, transformData(values))
      .then((res) => {
        message.success(res.data.message);
        refresh();
      })
      .catch((err) => message.error(err.response.data.message));
  };

  // Calculate totals for a given diet data
  const calculateDietTotals = (data) => {
    return data.reduce(
      (totals, item) => {
        totals.proteins += item.proteins || 0;
        totals.calories += item.calories || 0;
        totals.sodium += item.sodium || 0;
        totals.potassium += item.potassium || 0;
        totals.carbohydrate += item.carbohydrate || 0;
        return totals;
      },
      { proteins: 0, calories: 0, sodium: 0, potassium: 0, carbohydrate: 0 }
    );
  };

  // Map diet data to custom tags
  const mapToCustomTag = (data, color) => {
    return Object.entries(data).map(([key, value]) =>
      customTag(key, value, color)
    );
  };

  const morningData = calculateDietTotals(dietData.morning);
  const afternoonData = calculateDietTotals(dietData.afternoon);
  const eveningData = calculateDietTotals(dietData.evening);

  const overallData = [
    ...(dietData?.morning || []),
    ...(dietData?.afternoon || []),
    ...(dietData?.evening || []),
  ];
  const overAllCalc = calculateDietTotals(overallData);

  return (
    <Form onFinish={onFinish} form={form} ref={formRef}>
      <Card
        title={<Space>Diet Plan {mapToCustomTag(overAllCalc, "purple")}</Space>}
        style={{ width: "100%" }}
      >
        <div style={{ height: "560px", overflowY: "auto" }}>
          <Collapse
            defaultActiveKey={["1"]}
            expandIconPosition="right"
            className="custom-collapse"
          >
            <Panel
              header={
                <Space>Morning {mapToCustomTag(morningData, "orange")}</Space>
              }
              key="1"
            >
              <DietTable
                typeList={morningKeys}
                type="morning"
                onChange={(data) => handleTableChange("morning", data)}
              />
            </Panel>
            <Panel
              header={
                <Space>
                  Afternoon {mapToCustomTag(afternoonData, "orange")}
                </Space>
              }
              key="2"
            >
              <DietTable
                typeList={afternoonKeys}
                type="afternoon"
                onChange={(data) => handleTableChange("afternoon", data)}
              />
            </Panel>
            <Panel
              header={
                <Space>Night {mapToCustomTag(eveningData, "orange")}</Space>
              }
              key="3"
            >
              <DietTable
                typeList={eveningKeys}
                type="evening"
                onChange={(data) => handleTableChange("evening", data)}
              />
            </Panel>
          </Collapse>
        </div>
        <Form.Item style={{ textAlign: "right", marginTop: "10px" }}>
          <Button type="primary" htmlType="submit" className={btnStyles.mdBtn}>
            Submit
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default NurtritionistDietChart;
