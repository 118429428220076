import {
  Button,
  Form,
  InputNumber,
  Radio,
  message,
  Input,
  Typography,
  Card,
  Row,
  Col,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getPeakFlowMeterAPI,
  peakFlowMeterAPI,
  updatePeakFlowMeterAPI,
} from "../../../../../services/physio.services";
import { genderOptions } from "../../../Options/systemicExamOptions";

const PeakFlowMeterTest = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const handleChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      ip_admission: admission,
      predicted_PEFR: predictPefr(),
    };

    try {
      if (scalesId?.pefr) {
        await updatePeakFlowMeterAPI(scalesId?.pefr, finalData);
      } else {
        const response = await peakFlowMeterAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, pefr: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }

      // message.success("Form filled successfully");

      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "Close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to submit the form");
    }
  };

  const handleView = async () => {
    if (scalesId?.pefr) {
      getPeakFlowMeterAPI(admission, scalesId?.pefr)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          setStates({
            gender: formData?.gender,
            height: formData?.height,
            age: formData?.age,
          });
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const predictPefr = () => {
    const { gender, age, height } = states;
    if (gender && age && height) {
      switch (gender) {
        case "male adult":
          return Math.round(((height / 100) * 5.48 + 1.58 - age * 0.041) * 60);
        case "female adult":
          return Math.round(((height / 100) * 3.72 + 2.24 - age * 0.03) * 60);
        case "child":
          return Math.round((height - 100) * 5 + 100);
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="cardless-scale">
        <Card style={{ width: "800px" }}>
          <Form
            id="peak-flow-meter"
            form={form}
            onFinish={(values) => handleSubmitWithAction(values, "continue")}
            layout="vertical"
          >
            <Form.Item label="Gender" name="gender">
              <Radio.Group
                options={genderOptions}
                onChange={(e) => handleChange("gender", e.target.value)}
              />
            </Form.Item>
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <Form.Item label="Age" name="age">
                  <InputNumber
                    max={150}
                    min={0}
                    addonAfter="yrs"
                    onChange={(value) => handleChange("age", value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Height" name="height">
                  <InputNumber
                    min={0}
                    addonAfter="cms"
                    onChange={(value) => handleChange("height", value)}
                  />
                </Form.Item>
              </Col>

              <br />
              <Col span={12}>
                <Typography style={{ fontSize: "16px" }}>
                  Predicted PEFR: {predictPefr()}
                </Typography>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography style={{ fontSize: "16px" }}>
                    Tested PEFR:
                  </Typography>
                  <Form.Item name="test_PEFR">
                    <InputNumber />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <br />
            <br />
            <Form.Item className="scales-comments-formitem" name="comments">
              <Input.TextArea rows={4} placeholder="Comments" />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "Close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default PeakFlowMeterTest;
