import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Radio, Slider, Typography } from "antd";
import {
  getVaScaleApi,
  updateVaScaleApi,
  vaScaleAPI,
} from "../../../../../services/physio.services";
import noPainSvg from "../../../../../assets/images/scalesImages/noPain.svg";
import lightPainSvg from "../../../../../assets/images/scalesImages/lightPain.svg";
import moderatePainSvg from "../../../../../assets/images/scalesImages/moderatePain.svg";
import severePainSvg from "../../../../../assets/images/scalesImages/severePain.svg";
import verySeverePainSvg from "../../../../../assets/images/scalesImages/verySeverePain.svg";
import worstPainSvg from "../../../../../assets/images/scalesImages/worstPain.svg";
import PainSelection from "./VAScaleComponents/PainSelection";

const VAScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const vasOptions = [
    {
      value: 0,
      label: "No Pain",
      image: noPainSvg,
    },
    {
      value: 1,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 2,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 3,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 4,
      label: "Moderate",
      image: moderatePainSvg,
    },
    {
      value: 5,
      label: "Moderate",
      image: moderatePainSvg,
    },
    {
      value: 6,
      label: "Severe",
      image: severePainSvg,
    },
    {
      value: 7,
      label: "Severe",
      image: severePainSvg,
    },
    {
      value: 8,
      label: "Very Severe",
      image: verySeverePainSvg,
    },
    {
      value: 9,
      label: "Very Severe",
      image: verySeverePainSvg,
    },
    {
      value: 10,
      label: "Worst Pain",
      image: worstPainSvg,
    },
  ];

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      interpretation: vasOptions[values?.pain]?.label,
      ip_admission: admission,
    };

    try {
      if (Object.keys(formData)?.length !== 0) {
        updateVaScaleApi(scalesId?.vas, finalData);
      } else {
        const response = await vaScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, vas: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.vas) {
      getVaScaleApi(admission, scalesId?.vas)
        .then((response) => {
          form.setFieldsValue(response.data);
          setFormData(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        {/* <Typography>Visual Analog Scale</Typography> */}
        <Form
          id="vas"
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
          form={form}
        >
          <PainSelection />

          <Form.Item className="scales-comments-formitem" name="comments">
            <Input.TextArea rows={4} placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default VAScale;
