import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import useQuery from "../../../../hooks/useQuery";
import { getPatientDietItemsApi } from "../../../../services/nutritionist.services";
import DietView from "./DietView";
import NurtritionistDietChart from "./NurtritionistDietChart";

const NutritionistDiet = () => {
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [dietData, setDietData] = useState();
  const [refresh, setRefresh] = useState(false);

  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    ?.replaceAll("_", "-");

  const refreshDietData = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    getPatientDietItemsApi(params?.patient)
      .then((res) => {
        setDietData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  return (
    <>
      {dietData && Object.keys(dietData).length !== 0 ? (
        <DietView dietData={dietData} refresh={refreshDietData} />
      ) : role === "nutritionist" ? (
        <NurtritionistDietChart refresh={refreshDietData} />
      ) : null}
    </>
  );
};

export default NutritionistDiet;
