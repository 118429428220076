import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Typography,
  message,
  Row,
  Col,
} from "antd";
import {
  getMmtScaleAPI,
  mmtScaleAPI,
  updateMmtScaleAPI,
} from "../../../../../services/physio.services";
import { mmtScaleOptions } from "../../../Options/physioOptions";

const MMTScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const limbOptions = [
    {
      title: "Left",
      options: [
        { label: "Upper Left Limb", value: "upper_left_limb" },
        { label: "Lower Left Limb", value: "lower_left_limb" },
      ],
    },
    {
      title: "Right",
      options: [
        { label: "Upper Right Limb", value: "upper_right_limb" },
        { label: "Lower Right Limb", value: "lower_right_limb" },
      ],
    },
  ];

  const handleSubmitWithAction = async (values, action) => {
    const finalData = { ...values, ip_admission: admission };
    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateMmtScaleAPI(scalesId?.mmts, finalData);
      } else {
        const response = await mmtScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, mmts: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.mmts) {
      try {
        const response = await getMmtScaleAPI(admission, scalesId?.mmts);
        form.setFieldsValue(response.data);
        setFormData(response.data);
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Form
          form={form}
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
        >
          <Row gutter={[32, 32]}>
            {limbOptions.map((option) => (
              <Col span={12} style={{ width: "450px" }}>
                {/* <Card
                  key={option.title}
                  title={option.title}
                  style={{ width: "600px" }}
                > */}
                {option.options.map((limb) => (
                  <React.Fragment key={limb.value}>
                    <Typography>{limb.label}</Typography>
                    <Form.Item name={limb.value}>
                      <Select
                        style={{ height: 100 }}
                        options={mmtScaleOptions.map((option) => ({
                          label: (
                            <div
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {option.label}
                            </div>
                          ),
                          value: option.value,
                        }))}
                      />
                    </Form.Item>
                  </React.Fragment>
                ))}
                {/* </Card> */}
                <br />
              </Col>
            ))}
          </Row>
          <br />
          <br />
          <Form.Item name="comments" className="scales-comments-formitem">
            <Input.TextArea rows={4} placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default MMTScale;
