import { Button, Col, Drawer, Row, Tabs, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PsychologistInitialInspectionSummary from "./PsychologistInitialInspectionSummary";
import { CloseOutlined } from "@ant-design/icons";
import PsychologistInitialInspectionForm from "./PsychologistInitialInspectionForm";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import { getPhysioInspectionAPI } from "../../../../services/physio.services";
import { PsychologistInitialContext } from "./PsychologistInitialAssessmentProvider";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { getPsychologistInspectionAPI } from "../../../../services/psychologist.services";
import { getLabel } from "../../InspectionSummaries/constructTextFromValues";

const PsychologistInitialInspection = ({
  patientData,
  editId,
  refreshPage,
}) => {
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(PsychologistInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getPsychologistInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };

  // const handleTabChange = (key) => {
  //   if (isDirty) {
  //     Modal.confirm({
  //       title: "Unsaved changes",
  //       content:
  //         "You have unsaved changes. Are you sure you want to save these changes?",
  //       onOk: () => {
  //         switch (activeTab) {
  //           case "1":
  //             demographicForm.validateFields().then((values) => {
  //               setIsDirty(false);
  //               onDemographicSubmit(values);
  //               setActiveTab(key);
  //             });
  //             break;
  //           case "2":
  //             counsellingForm.validateFields().then((values) => {
  //               setIsDirty(false);
  //               onCounsellingSubmit(values);
  //               setActiveTab(key);
  //             });
  //             break;
  //           case "3":
  //             symptomsForm.validateFields().then((values) => {
  //               setIsDirty(false);
  //               onSymptomsSubmit(values);
  //               setActiveTab(key);
  //             });
  //           case "4":
  //             diagnosisForm.validateFields().then((values) => {
  //               setIsDirty(false);
  //               onSymptomsSubmit(values);
  //               setActiveTab(key);
  //             });
  //           case "5":
  //             therapyForm.validateFields().then((values) => {
  //               setIsDirty(false);
  //               onSymptomsSubmit(values);
  //               setActiveTab(key);
  //             });
  //           default:
  //             setActiveTab(key);
  //         }
  //       },
  //     });
  //   } else {
  //     setActiveTab(key);
  //   }
  // };

  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);
  console.log("patient", patientData);
  return (
    <>
      {editId ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(false);
              }}
              className={btnStyles.lgBtn}
            >
              Edit
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <PsychologistInitialInspectionSummary editId={editId} />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(true);
              }}
              className={btnStyles.lgBtn}
            >
              Initial Assessment Form
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        <Drawer
          open={visible}
          width="100%"
          // title={
          //   <Row>
          //     <Col span={4} className="flex-start">
          //       <Typography
          //         style={{
          //           display: "flex",
          //           flexDirection: "column",
          //           marginLeft: "12px",
          //           fontWeight: "400",
          //           fontSize: "14px",
          //         }}
          //       >
          //         {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}
          //         <span>
          //           {" "}
          //           {getLabel(patientData?.admission?.patient?.gender)}
          //           {getLabel(patientData?.admission?.patient?.age)}
          //         </span>
          //       </Typography>
          //     </Col>
          //     <Col span={16}>
          //       <Tabs
          //         activeKey={activeTab}
          //         items={[
          //           { key: "1", label: "Patient Demographics" },
          //           { key: "2", label: "Previous Counselling / Psychotherapy" },
          //           { key: "3", label: "Symptoms / Impressions" },
          //           { key: "4", label: "Psychology Diagnosis" },
          //           { key: "5", label: "Therapies Suggested" },
          //         ]}
          //         // onChange={handleTabChange}
          //       />
          //     </Col>
          //     <Col span={4} className="flex-end">
          //       <CloseOutlined onClick={() => setConfirmModal(true)} />
          //     </Col>
          //   </Row>
          // }
          footer={null}
          onClose={() => setVisible(false)}
          closeIcon={null}
          keyboard={false}
        >
          <PsychologistInitialInspectionForm
            visible={visible}
            setVisible={setVisible}
            refreshPage={refreshPage}
            initialRefresh={setInitialAssessmentRefresh}
            editId={editId}
            patientData={patientData}
            setConfirmModal={setConfirmModal}
          />

          {confirmModal && (
            <ClosePopup
              open={confirmModal}
              setOpen={setConfirmModal}
              setVisible={setVisible}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

export default PsychologistInitialInspection;
