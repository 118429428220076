import { Col, Form, Radio, Row } from "antd";
import React from "react";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import lionSvg from "../../../../../../assets/images/scalesImages/lion.svg";
import hippoSvg from "../../../../../../assets/images/scalesImages/hippo.svg";
import camelSvg from "../../../../../../assets/images/scalesImages/camel.svg";

const Naming = () => {
  const namingFieldOptions = [
    { image: lionSvg, value: "lion" },
    { image: hippoSvg, value: "hippo" },
    { image: camelSvg, value: "camel" },
  ];

  return (
    <Row gutter={[16, 16]} justify="center">
      {namingFieldOptions.map((field) => (
        <Col key={field.value} span={8} style={{ textAlign: "center" }}>
          <img
            src={field.image}
            alt={field.value}
            style={{
              width: "100%",
              // maxWidth: "150px",
              height: "auto",
              borderRadius: 16,
              marginBottom: 16,
              background: "#fff",
            }}
          />
          <Form.Item
            name={["naming_data", field.value]}
            style={{ marginBottom: 0 }}
          >
            <Radio.Group>
              <Radio.Button value={"yes"} className="like-btn">
                <LikeOutlined />
              </Radio.Button>
              <Radio.Button value={"no"} className="dislike-btn">
                <DislikeOutlined />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default Naming;
