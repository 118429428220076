import React from "react";
import { Card, Col, Image, Row, Typography } from "antd";
import { ResponsiveMasonry } from "react-responsive-masonry";
import dayjs from "dayjs";
import "./PatientImageView.css";
import NoDataPage from "../../NoDataPages/NoDataPage";
import GalleryIcon from "../../../assets/images/nurseIcons/gallery.svg";

const { Meta } = Card;
const PatientImageView = ({ data }) => {
  const fileData = [
    ...new Set(
      data?.flatMap((imgs) =>
        imgs.paths.map((action) => {
          return { ...action, date: imgs?.uploaded_at };
        })
      )
    ),
  ];

  return (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh)",
      }}
      className="gallery-card-container"
    >
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 300: 3, 500: 4, 700: 3, 900: 5 }}
        // gutter={"6px"}
      >
        <div className="gallery-img-container">
          {data?.length > 0 ? (
            <Row gutter={[16, 16]}>
              {fileData?.map((item, index) => (
                <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div
                    className="gallery-img-card"
                    hoverable
                    style={{ width: 250 }}
                  >
                    <Image
                      style={{ width: "250px", height: "150px" }}
                      alt={item?.path?.replace("curengo/", "") || "Image"}
                      src={item?.url}
                    />
                    <Meta
                      title={
                        <div className="gallery-meta-title">
                          <Typography.Text ellipsis>
                            {item?.path?.replace("curengo/", "")}
                          </Typography.Text>
                        </div>
                      }
                      description={
                        <div className="gallery-meta-desc">
                          {item?.date
                            ? dayjs(item.date).format("DD MMM")
                            : "Unknown Date"}
                        </div>
                      }
                    />
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <NoDataPage
              title={"Gallery Empty"}
              subtitle={"No images to be shown at the moment!"}
              image={GalleryIcon}
            />
          )}
        </div>
      </ResponsiveMasonry>
    </div>
  );
};

export default PatientImageView;
