import React, { useContext, useEffect, useState } from "react";
import PhysiotherapistInitialInspectionSummary from "./PhysiotherapistInitialInspectionSummary";
import PhysiotherapistInitialInspectionForm from "./PhysiotherapistInitialInspectionForm";
import { PhysioInitialContext } from "./PhysiotherapistInitialAssessmentProvider";
import { getPhysioInspectionAPI } from "../../../../services/physio.services";
import { Button, Col, Drawer, Row, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import btnStyles from "../../../../styles/Custom/Button.module.css";

const PhysiotherapistInitialInspection = ({
  refreshPage,
  editId,
  patientData,
}) => {
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(PhysioInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getPhysioInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);
  return (
    <>
      {editId ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(false);
              }}
              className={btnStyles.lgBtn}
            >
              Edit
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <PhysiotherapistInitialInspectionSummary editId={editId} />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(true);
              }}
              className={btnStyles.lgBtn}
            >
              Initial Assessment Form
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        <Drawer
          open={visible}
          width="100%"
          // title={
          //   <Row>
          //     <Col span={4} className="flex-start">
          //       <Typography
          //         style={{
          //           marginLeft: "12px",
          //           fontWeight: "400",
          //           fontSize: "14px",
          //         }}
          //       >
          //         {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}
          //       </Typography>
          //     </Col>

          //     <Col span={20} className="flex-end">
          //       <CloseOutlined onClick={() => setConfirmModal(true)} />
          //     </Col>
          //   </Row>
          // }
          footer={null}
          onClose={() => setVisible(false)}
          closeIcon={null}
          keyboard={false}
        >
          <PhysiotherapistInitialInspectionForm
            visible={visible}
            setVisible={setVisible}
            refreshPage={refreshPage}
            initialRefresh={setInitialAssessmentRefresh}
            editId={editId}
            patientData={patientData}
            setConfirmModal={setConfirmModal}
          />

          {confirmModal && (
            <ClosePopup
              open={confirmModal}
              setOpen={setConfirmModal}
              setVisible={setVisible}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

export default PhysiotherapistInitialInspection;
