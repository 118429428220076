import React, { useState } from "react";
import { Form, Card, Collapse, Space, Button, message, Row, Col } from "antd";
import {
  morningKeys,
  afternoonKeys,
  eveningKeys,
  mergeDietData,
  customTag,
  transformData,
} from "../nutritionistKeys";
import DietEditTable from "./DietEditTable";
import {
  deleteDietItems,
  updatePatientDietItemsApi,
} from "../../../../../services/nutritionist.services";
import useQuery from "../../../../../hooks/useQuery";
import btnStyles from "../../../../../styles/Custom/Button.module.css";
import { EyeOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const DietEditChart = ({ dietEditData, refresh, setEditState }) => {
  const mergedMorningData = mergeDietData(
    morningKeys,
    dietEditData?.morning || []
  );
  const mergedAfternoonData = mergeDietData(
    afternoonKeys,
    dietEditData?.afternoon || []
  );
  const mergedEveningData = mergeDietData(
    eveningKeys,
    dietEditData?.evening || []
  );
  const params = useQuery();
  const [form] = Form.useForm();
  const [dietData, setDietData] = useState({
    morning: mergedMorningData,
    afternoon: mergedAfternoonData,
    evening: mergedEveningData,
  });

  const handleTableChange = (type, updatedData) => {
    setDietData((prevData) => {
      const newData = { ...prevData, [type]: updatedData };
      // Update overall totals here if needed
      return newData;
    });
  };

  const calculateTotals = (data) => {
    if (!data || data.length === 0) {
      return {
        carbohydrates: 0,
        proteins: 0,
        fats: 0,
        calories: 0,
        sodium: 0,
        potassium: 0,
      };
    }
    return data?.reduce(
      (totals, item) => ({
        proteins: (totals.proteins || 0) + (item.proteins || 0),
        calories: (totals.calories || 0) + (item.calories || 0),
        sodium: (totals.sodium || 0) + (item.sodium || 0),
        potassium: (totals.potassium || 0) + (item.potassium || 0),
        carbohydrates: (totals.carbohydrates || 0) + (item.carbohydrates || 0),
      }),
      {}
    );
  };

  const mapToCustomTag = (data, color) => {
    return Object.entries(data).map(([key, value]) =>
      customTag(key, value, color)
    );
  };

  const morningTotals = calculateTotals(dietData?.morning);
  const afternoonTotals = calculateTotals(dietData?.afternoon);
  const eveningTotals = calculateTotals(dietData?.evening);

  const combinedTotals = {
    proteins:
      morningTotals.proteins +
      afternoonTotals.proteins +
      eveningTotals.proteins,
    calories:
      morningTotals.calories +
      afternoonTotals.calories +
      eveningTotals.calories,
    sodium:
      morningTotals.sodium + afternoonTotals.sodium + eveningTotals.sodium,
    potassium:
      morningTotals.potassium +
      afternoonTotals.potassium +
      eveningTotals.potassium,
    carbohydrates:
      morningTotals.carbohydrates +
      afternoonTotals.carbohydrates +
      eveningTotals.carbohydrates,
  };

  async function onFinish(values) {
    const removed = removeUnmatchedDietItems(values, dietEditData);

    if (removed?.length > 0) {
      deleteDietItems(params?.patient, removed)
        ?.then((res) => {
          message.success(res?.data?.message);
          updatePatientDietItemsApi(params?.patient, transformData(values))
            .then((res) => {
              // message.success(res?.data?.message);
              refresh();
              setEditState(false);
            })
            .catch((err) => console.log(err?.response?.data?.message));
        })
        .catch((err) => console.log(err));
    } else {
      updatePatientDietItemsApi(params?.patient, transformData(values))
        .then((res) => {
          message.success(res?.data?.message);
          refresh();
          setEditState(false);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    }
  }

  function removeUnmatchedDietItems(formData, dietData) {
    let result = {};

    // Define time slots to check (morning, afternoon, evening)
    const timeSlots = ["morning", "afternoon", "evening"];

    timeSlots.forEach((slotType) => {
      // Iterate over first data keys (time slots like '06:00 AM')
      Object.keys(formData).forEach((timeKey) => {
        // Extract hour in 24-hour format from time key (e.g., "07:00 AM" => 7)
        const hour = parseInt(timeKey.split(":")[0], 10);

        // Find the matching time in the corresponding dietData slot (morning/afternoon/evening)
        const secondTimeSlot = dietData[slotType]?.find(
          (slot) => slot.time === hour
        );

        if (secondTimeSlot) {
          // Get the diet__id values from formData and dietData for the current slotType
          const firstDietIds = formData[timeKey][slotType]?.items;
          const secondDietIds = secondTimeSlot.items.map(
            (item) => item.diet__id
          );

          // Find the ids that are in dietData but not in formData
          const removedDietIds = secondTimeSlot.items.filter(
            (item) => !firstDietIds.includes(item.diet__id)
          );

          // If there are removed diet ids, store them in the result
          if (removedDietIds.length > 0) {
            result[timeKey] = result[timeKey] || {}; // Initialize if not already
            result[timeKey][slotType] = removedDietIds;
          }
        }
      });
    });

    // Flatten the result and return the IDs of removed items
    return Object.values(result).flatMap((timeData) =>
      Object.values(timeData)
        .flat()
        .map((item) => item.id)
    );
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Card
        title={
          <Row>
            <Col span={16}>Diet Items</Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <EyeOutlined
                onClick={() => setEditState(false)}
                style={{ fontSize: "20px" }}
              />
            </Col>
            <Col span={24}>{mapToCustomTag(combinedTotals, "purple")}</Col>
          </Row>
        }
      >
        <div style={{ height: "560px", overflowY: "auto" }}>
          <Collapse
            defaultActiveKey={["0"]}
            expandIconPosition="right"
            className="custom-collapse"
          >
            <Panel
              header={
                <Space>Morning {mapToCustomTag(morningTotals, "orange")}</Space>
              }
            >
              <DietEditTable
                type="morning"
                data={mergedMorningData}
                form={form}
                refresh={refresh}
                onChange={(data) => handleTableChange("morning", data)}
              />
            </Panel>
            <Panel
              header={
                <Space>
                  Afternoon {mapToCustomTag(afternoonTotals, "orange")}
                </Space>
              }
            >
              <DietEditTable
                type="afternoon"
                data={mergedAfternoonData}
                form={form}
                refresh={refresh}
                onChange={(data) => handleTableChange("afternoon", data)}
              />
            </Panel>
            <Panel
              header={
                <Space>Evening {mapToCustomTag(eveningTotals, "orange")}</Space>
              }
            >
              <DietEditTable
                type="evening"
                data={mergedEveningData}
                form={form}
                onChange={(data) => handleTableChange("evening", data)}
              />
            </Panel>
          </Collapse>
        </div>
      </Card>
      <Form.Item style={{ textAlign: "right", marginTop: "10px" }}>
        <Button type="primary" htmlType="submit" className={btnStyles.mdBtn}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DietEditChart;
