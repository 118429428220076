import { Form, InputNumber, Table, Typography } from "antd";
import React from "react";

const DisitalJoint = () => {
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Left Active",
      dataIndex: "left_active",
      key: "left_active",
      render: (text, record) => (
        <Form.Item name={["disital_joint", `left_active_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Left Passive",
      dataIndex: "left_passive",
      key: "left_passive",
      render: (text, record) => (
        <Form.Item name={["disital_joint", `left_passive_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Active",
      dataIndex: "right_active",
      key: "right_active",
      render: (text, record) => (
        <Form.Item name={["disital_joint", `right_active_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Passive",
      dataIndex: "right_passive",
      key: "right_passive",
      render: (text, record) => (
        <Form.Item name={["disital_joint", `right_passive_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      action: "Flexion",
      value: "flexion",
      max: 100,
      min: 0,
    },
    {
      key: "2",
      action: "Hyperextension",
      value: "hyperextension",
      max: 10,
      min: 0,
    },
  ];
  return (
    <>
      <Table dataSource={data} columns={columns} pagination={false} />
    </>
  );
};

export default DisitalJoint;
