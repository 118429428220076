import React from "react";
import {
  Space,
  Table,
  Image,
  Tag,
  Button,
  Statistic,
  Tooltip,
  message,
  Popconfirm,
} from "antd";
import { capitalizeFirstLetter, formatToINR } from "../../../helpers/utility";
import paymentIcon from "../../../assets/receptionistIcons/paymentIcon.svg";
import exportIcon from "../../../assets/receptionistIcons/exportIcon.svg";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  cancelBillApi,
  shareBillApi,
} from "../../../services/receptionist.services";

const BillingTable = ({ data, loading, refresh }) => {
  const navigate = useNavigate();

  const onCancel = (id) => {
    cancelBillApi(id)
      .then((res) => {
        message.success(res?.data?.message);
        refresh();
      })
      .catch((err) => message.error(err?.response?.data?.message));
  };

  const downLoadBill = (id) => {
    shareBillApi(id, `type=bill&share=download`)
      .then((res) => {
        const s3Link = res?.data?.data; // Assuming the S3 link is in res.data.data
        if (s3Link) {
          window.open(s3Link, "_blank"); // Open the link in a new tab
        } else {
          message.error("Failed to retrieve download link.");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Error downloading the bill.");
      });
  };

  const columns = [
    {
      title: "Bill No",
      dataIndex: "id",
      key: "id",
      width: 90,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => dayjs(date).format("MMM DD"),
      width: 90,
    },
    {
      title: "Patient",
      dataIndex: ["patient", "name"], // Correctly access nested data
      key: "patient_name",
      width: 150,
    },
    {
      title: "Total Amount",
      dataIndex: "gross_amount",
      key: "gross_amount",
      width: 100,
      render: (value, record) => (
        <Statistic
          value={formatToINR((value - record?.total_discount)?.toFixed(2))}
          valueStyle={{ fontSize: "16px" }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status) => (
        <Tag
          size="small"
          color={
            status === "paid"
              ? "green"
              : status === "cancelled"
              ? "red"
              : status === "pending"
              ? "orange"
              : "purple"
          }
          className={tagStyles.mdRndTag}
        >
          {capitalizeFirstLetter(status)?.split("_")[0]}
        </Tag>
      ),
    },
    {
      title: "Discount",
      dataIndex: "total_discount",
      key: "total_discount",
      width: 90,
    },
    {
      title: "Due Amount",
      dataIndex: "due_amount",
      key: "due_amount",
      width: 120,
      render: (value) => (
        <Statistic
          value={formatToINR(value)}
          valueStyle={{
            color: value !== 0 ? "#FD6050" : "#40C164",
            fontSize: "16px",
          }}
        />
      ),
    },
    {
      title: "Action",
      width: 300,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Download Bill">
            <Button onClick={() => downLoadBill(record?.id)}>
              <Image
                src={exportIcon}
                alt="export"
                preview={false}
                style={{ cursor: "pointer", width: "22px" }}
              />
            </Button>
          </Tooltip>
          {/* <Tooltip title="View Bill">
            <Button
              onClick={() =>
                navigate(
                  `/receptionist/billing/?patient=true&id=${record?.id}&patient_id=${record?.patient?.id}&view=true`
                )
              }
            >
              <EyeOutlined
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  color: "#475467",
                }}
              />
            </Button>
          </Tooltip> */}
          {(record?.status === "partial_payment" ||
            record?.status === "pending") && (
            <Tooltip title="Collect Payment">
              <Button
                onClick={() =>
                  navigate(
                    `/receptionist/billing/?patient=true&id=${record?.id}&patient_id=${record?.patient?.id}`
                  )
                }
              >
                <Image
                  src={paymentIcon}
                  alt="payment"
                  preview={false}
                  style={{ cursor: "pointer", width: "22px" }}
                />
              </Button>
            </Tooltip>
          )}
          {!(
            record?.status === "cancelled" || record?.admission_type === "IP"
          ) && (
            <Popconfirm
              title="Sure to cancel bill?"
              okText="Yes"
              cancelText="No"
              icon={null}
              onConfirm={() => onCancel(record?.id)}
            >
              <Tooltip title="Cancel Bill">
                <Button>
                  <CloseOutlined style={{ color: "red" }} />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      dataSource={data?.data}
      columns={columns}
      loading={loading}
      pagination={false}
      scroll={{ y: "calc(100vh - 300px)" }}
    />
  );
};

export default BillingTable;
