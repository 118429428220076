import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Pagination,
  Table,
  Typography,
} from "antd";
import { updateDietApi } from "../../../services/diet.services";
import inputStyles from "../../../styles/Custom/Input.module.css";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber className={inputStyles.mdNumberInput} step={1} min={0} />
    ) : (
      <Input className={inputStyles?.mdInput} style={{ width: "100%" }} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: record?.key === "name" && true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const DietTableEdit = ({
  loading,
  data,
  setData,
  page,
  setPage,
  refreshPage,
  userDetails,
}) => {
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record?.id === editingKey?.id;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      protien: "",
      calories: "",
      ...record,
    });
    setEditingKey(record);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (record) => {
    try {
      const updatedValues = await form.validateFields();
      const newData = [...data?.data];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...updatedValues });
        setData(newData);
        const res = await updateDietApi(record?.id, updatedValues);
        refreshPage();
        message.success(res?.data?.message);
        setEditingKey("");
      } else {
        message.error("Row not found");
      }
    } catch (err) {
      message.error("Something went wrong");
      console.error(err?.response?.data?.message?.name[0]);
    }
  };
  const columns = [
    {
      title: "Diet code",
      width: "10%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Diet Name",
      dataIndex: "name",
      editable: true,
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: "Protein",
      dataIndex: "protien",
      editable: true,
      sorter: (a, b) => a?.protien - b?.protien,
    },
    {
      title: "Calories",
      dataIndex: "calories",
      editable: true,
      sorter: (a, b) => a?.calories - b?.calories,
    },
    {
      title: "Sodium",
      dataIndex: "sodium",
      editable: true,
      sorter: (a, b) => a?.calories - b?.calories,
    },
    {
      title: "Potassium",
      dataIndex: "potassium",
      editable: true,
      sorter: (a, b) => a?.calories - b?.calories,
    },
    {
      title: "Carbohydrates",
      dataIndex: "carbohydrates",
      editable: true,
      sorter: (a, b) => a?.calories - b?.calories,
    },
    ...(userDetails?.activeOrg?.default_role === "Facility_Admin"
      ? []
      : [
          {
            title: "Action",
            dataIndex: "operation",
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link
                    onClick={() => save(record)}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Save
                  </Typography.Link>
                  <Typography.Link
                    onClick={cancel}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Cancel
                  </Typography.Link>
                </span>
              ) : (
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
              );
            },
          },
        ]),
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "name" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data?.data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: "calc(100vh - 290px)" }}
        />
      </Form>
      <div className="flex-center" style={{ paddingTop: "10px" }}>
        <Pagination
          current={page}
          pageSize={100}
          total={data?.total_pages}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};
export default DietTableEdit;
