import { Form, Radio, Card, Typography, Row, Col } from "antd";
import React from "react";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const visualFieldOptions = [
  { label: "Begin End", value: "begin_end" },
  { label: "Copy Cube", value: "copy_cube" },
  { label: "Contour", value: "contour" },
  { label: "Numbers", value: "numbers" },
  { label: "Hands", value: "hands" },
];

const Visual = () => {
  return (
    <Card style={{ padding: "20px", width: "600px" }}>
      {visualFieldOptions.map((field) => (
        <Row key={field.value} style={{ marginBottom: "16px" }} align="middle">
          <Col span={12}>
            <Typography>{field.label}</Typography>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["visual_executive_data", field.value]}
              style={{ marginBottom: 0 }}
            >
              <Radio.Group style={{ width: "100%" }} className="radio-colored">
                <Row justify="space-around" align="middle">
                  <Col>
                    <Radio.Button
                      value={"yes"}
                      // style={{ backgroundColor: "#E9D7FE" }}
                      className="like-btn"
                    >
                      <LikeOutlined />
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button
                      value={"no"}
                      // style={{ backgroundColor: "#FFF5F5" }}
                      className="dislike-btn"
                    >
                      <DislikeOutlined />
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      ))}
    </Card>
  );
};

export default Visual;
