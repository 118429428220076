import React from "react";
import PsychologistInitialAssessmentProvider from "../IpEMR/Psychologist/InitialInspection/PsychologistInitialAssessmentProvider";
import PsychologistInitialInspection from "../IpEMR/Psychologist/InitialInspection/PsychologistInitialInspection";
import PsychologistReviewProvider from "../IpEMR/Psychologist/Review/PsychologistReviewProvider";
import PsychologistReview from "../IpEMR/Psychologist/Review/PsychologistReview";

const PsychologistIP = ({ patientData, params, refreshPage }) => {
  return (
    <div>
      {params?.type === "ia" || params?.type === "landing" ? (
        <PsychologistInitialAssessmentProvider>
          <PsychologistInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.psychology_initial_assessment}
            patientData={patientData}
          />
        </PsychologistInitialAssessmentProvider>
      ) : params?.type === "review" ? (
        <PsychologistReviewProvider>
          <PsychologistReview
            reviewData={patientData?.psychology_review}
            iAssessment={patientData?.psychology_initial_assessment}
            refreshPage={refreshPage}
            patientData={patientData}
          />
        </PsychologistReviewProvider>
      ) : (
        ""
      )}
    </div>
  );
};

export default PsychologistIP;
