import { Button, Col, Divider, Form, Input, Modal, Row, message } from "antd";
import {
  createPatientMonitoringAPI,
  updatePatientMonitoringAPI,
} from "../../../services/patient.services";

const { TextArea } = Input;

function AddNotes({ patient, form, getData, isModalOpen, setIsModalOpen }) {
  async function onFinish(values) {
    try {
      const res =
        isModalOpen?.type === "add"
          ? await createPatientMonitoringAPI(patient, values)
          : await updatePatientMonitoringAPI(patient, isModalOpen?.id, values);
      message.success(res.data.message);
      setIsModalOpen({ status: false, type: "add", id: 0 });
      getData();
      console.log(res);
    } catch (error) {
      message.success(error.response.data.message);
    }
  }

  return (
    <Modal
      footer={null}
      title={isModalOpen?.type === "add" ? "Add Notes" : "Update Notes"}
      open={isModalOpen}
      onCancel={() => setIsModalOpen({ status: false, type: "add", id: 0 })}
    >
      <Form form={form} name="notes" layout="vertical" onFinish={onFinish}>
        <Row style={{ marginTop: "18px" }}>
          <Col span={24}>
            <Form.Item
              label="Progress Notes"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Please input progress notes!",
                },
              ]}
            >
              <TextArea
                style={{ minHeight: "200px" }}
                placeholder="Add Progress Notes"
              />
            </Form.Item>
          </Col>
          <Col span={24} className="flex-end">
            <Form.Item wrapperCol={{ span: 4 }}>
              <Button type="primary" htmlType="submit">
                {isModalOpen?.type === "add" ? "Add" : "Update"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddNotes;
