import React, { useEffect, useState } from "react";
import { Col, Drawer, Row, Skeleton, Space, Typography } from "antd";
import { patientInvestigationsViewAPI } from "../../../../services/patient.services";
import { CloseOutlined } from "@ant-design/icons";
import AllImageView from "./AllImageView";
import "./AllImageView.css";

const InvestigationGallery = ({ open, setOpen, id, editData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  console.log(editData);
  useEffect(() => {
    patientInvestigationsViewAPI(
      `patient_id=${id || editData?.ip_admission_id}&ip_admission=${
        id || editData?.ip_admission_id
      }&gallery=${true}`
    )
      .then((res) => {
        setData(res?.data);

        setFilteredData(res?.data?.filter((item) => item?.id === editData?.id));

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editData?.id]);

  return (
    <Drawer
      width={"100%"}
      open={open}
      onClose={() => setOpen(false)}
      closeIcon={null}
      footer={null}
      style={{ backgroundColor: "#f3f5f8" }}
      title={
        <Row
          style={{
            backgroundColor: "#d0c0f2",
            border: "1px solid #d0d5dd",
            padding: "16px",
          }}
        >
          <Col span={20}>
            <Space>
              <Typography>Investigation Gallery View</Typography>
            </Space>
          </Col>
          <Col span={4} className="flex-end">
            <CloseOutlined onClick={() => setOpen(false)} />
          </Col>
        </Row>
      }
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <AllImageView data={editData ? filteredData : data} />
      )}
    </Drawer>
  );
};

export default InvestigationGallery;
