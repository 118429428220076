import React, { useState } from "react";
import { Modal, Form, TimePicker, Input, Button, message } from "antd";
import dayjs from "dayjs";
import { updateNurseCommonData } from "../../../services/nurse.services";

const format = "hh:mm A";
const StatMedicineModal = ({
  open,
  setOpen,
  frequency,
  actions,
  refreshPage,
}) => {
  const [selectedTime, setSelectedTime] = useState(new Date().toISOString());

  const onFinish = (values) => {
    const updatedMedicine = {
      process_time: selectedTime,
      action: "done",
      remarks: values?.remarks,
    };
    const sosMedicines = actions?.[0]?.nurse_data
      ? [...actions?.[0]?.nurse_data, updatedMedicine]
      : [updatedMedicine];
    const formData =
      frequency === "Immediately"
        ? updatedMedicine
        : { nurse_data: sosMedicines };
    updateNurseCommonData(actions?.[0]?.id, formData)
      .then((res) => {
        message.success(res.data.message);
        refreshPage();
        setOpen(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Time Done"
            name="process_time"
            initialValue={dayjs(selectedTime)}
          >
            <TimePicker
              needConfirm={false}
              format={format}
              value={dayjs(selectedTime)}
              onChange={(date) => setSelectedTime(date)}
            />
          </Form.Item>
          <Form.Item label="Remarks" name="remarks">
            <Input.TextArea />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default StatMedicineModal;
