import { Descriptions } from "antd";
import React from "react";
import { getGussSummary, getLabelTagInput } from "../constructTextFromValues";

const SpeechSwallowScalesSummary = ({ formData }) => {
  const constructScalesSummaryItems = () => {
    const scalesSummaryItems = [];
    const scales = formData?.scales || {};
    const pushScalesItem = (key, label, children) => {
      scalesSummaryItems.push({ key, label, children });
    };
    const checkEmpty = (data) => {
      return Object.keys(data).length !== 0;
    };
    if (
      checkEmpty(scales?.direct_swallowing_liquid) ||
      checkEmpty(scales?.direct_swallowing_semisolid) ||
      checkEmpty(scales?.direct_swallowing_solid) ||
      checkEmpty(scales?.indirect_swallowing)
    ) {
      pushScalesItem(
        "1",
        "GUSS",
        <>
          {getLabelTagInput("Guss Score", scales?.guss_score)}
          {scales?.indirect_swallowing &&
            getLabelTagInput(
              "Indirect Swallowing",
              <>
                {getLabelTagInput("Score", scales?.indirect_swallowing?.score)}
                {getGussSummary(scales?.indirect_swallowing)}
              </>
            )}
          {scales?.direct_swallowing_semisolid &&
            getLabelTagInput(
              "Direct Swallowing Semi-solid",
              <>
                {getLabelTagInput(
                  "Score",
                  scales?.direct_swallowing_semisolid?.score
                )}
                {getGussSummary(scales?.direct_swallowing_semisolid)}
              </>
            )}
          {scales?.direct_swallowing_liquid &&
            getLabelTagInput(
              "Direct Swallowing Liquid",
              <>
                {getLabelTagInput(
                  "Score",
                  scales?.direct_swallowing_liquid?.score
                )}
                {getGussSummary(scales?.direct_swallowing_liquid)}
              </>
            )}
          {scales?.direct_swallowing_solid &&
            getLabelTagInput(
              "Direct Swallowing Solid",
              <>
                {getLabelTagInput(
                  "Score",
                  scales?.direct_swallowing_solid?.score
                )}
                {getGussSummary(scales?.direct_swallowing_solid)}
              </>
            )}
        </>
      );
    }

    if (checkEmpty(scales?.masa_details)) {
      pushScalesItem(
        "2",
        "MASA",
        <>
          {scales?.masa_details?.general_patient_examination &&
            getLabelTagInput(
              "General Patient Examination",
              <>{getGussSummary(scales?.general_patient_examination_details)}</>
            )}
          {scales?.masa_details?.oral_phase &&
            getLabelTagInput(
              "Oral Phase",
              <>{getGussSummary(scales?.oral_phase_details)}</>
            )}
          {scales?.masa_details?.oral_preparation_main &&
            getLabelTagInput(
              "Oral Preparation",
              <>{getGussSummary(scales?.oral_preparation_main_details)}</>
            )}
          {scales?.masa_details?.pharyngeal_phase &&
            getLabelTagInput(
              "Pharyngeal Phase",
              <>{getGussSummary(scales?.pharyngeal_phase_details)}</>
            )}
        </>
      );
    }
    return scalesSummaryItems;
  };
  const scalesSummaryItems = constructScalesSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Scales"
        items={scalesSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default SpeechSwallowScalesSummary;
