import { Form, Select, Row, Col, Card } from "antd";
import React from "react";
import {
  asiaImpairmentGradeOptions,
  neurologicalOptions,
} from "../../../../Options/physioOptions";

const NeurologicalLevels = () => {
  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label="Sensory Left" name={"sensory_left"}>
            <Select options={neurologicalOptions} style={{ width: "60px" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Sensory Right" name={"sensory_right"}>
            <Select options={neurologicalOptions} style={{ width: "60px" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Motor Left" name={"motor_left"}>
            <Select options={neurologicalOptions} style={{ width: "60px" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label="Motor Right" name={"motor_right"}>
            <Select options={neurologicalOptions} style={{ width: "60px" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Neurological Level Injury"
            name={"neurological_level_of_injury"}
          >
            <Select options={neurologicalOptions} style={{ width: "60px" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="ASIA Impairment Scale Grade"
            name={"asia_impairment_scale"}
          >
            <Select
              options={asiaImpairmentGradeOptions}
              style={{ width: "60px" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label="Zone of Partial Preservation"
            name={"zone_of_partial_preservation"}
          >
            <Select options={neurologicalOptions} style={{ width: "30%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default NeurologicalLevels;
