import { Descriptions } from "antd";
import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithDescription,
} from "../constructTextFromValues";
import {
  dietOptions,
  entrealDietOptions,
  entrealFeedTypeOptions,
  oralDietOptions,
  oralDietTypeOptions,
  otherSpecificationsOptions,
} from "../../Options/dietAdviceOptions";
import { yesNoOptions } from "../../Options/commonOptions";

const NutritionistDietAdviceSummary = ({ formData }) => {
  const constructDietAdviceSummaryItems = () => {
    const dietAdviceSummaryItems = [];
    const pushDietAdviceItem = (key, label, children) => {
      dietAdviceSummaryItems.push({ key, label, children });
    };
    const diet = formData?.diet_advice || {};
    if (diet?.calories) {
      pushDietAdviceItem(
        "1",
        "Calories & Protein",
        <>
          {getLabelTagInput("Calories", diet?.calories)}
          {getLabelTagInput("Protein", diet?.protein)}
        </>
      );
    }
    if (diet?.diet_details?.diet) {
      pushDietAdviceItem(
        "2",
        "Type of Diet",
        <>
          {getLabelTag(dietOptions, diet?.diet_details?.diet)}
          {getLabelTag(oralDietOptions, diet?.diet_details?.type_of_diet)}
          {getLabelTag(entrealDietOptions, diet?.diet_details?.type_of_diet)}
          {getLabelTag(
            oralDietTypeOptions,
            diet?.diet_details?.type_of_oral_diet
          )}
          {getLabelTag(
            entrealFeedTypeOptions,
            diet?.diet_details?.type_of_enteral_feed
          )}
        </>
      );
    }
    if (
      getLabelTagsWithDescription(
        otherSpecificationsOptions,
        diet?.other_specifications
      )
    ) {
      pushDietAdviceItem(
        "3",
        "Other Specifications",
        getLabelTagsWithDescription(
          otherSpecificationsOptions,
          diet?.other_specifications
        )
      );
    }
    if (diet?.patient_family_education) {
      pushDietAdviceItem(
        "4",
        "Patient Family Education",
        getLabelTag(yesNoOptions, diet?.patient_family_education)
      );
    }
    return dietAdviceSummaryItems;
  };
  const dietAdviceSummaryItems = constructDietAdviceSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={"Diet Advice"}
        items={dietAdviceSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </div>
  );
};

export default NutritionistDietAdviceSummary;
