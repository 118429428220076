import React, { useContext, useEffect } from "react";
import EmotionalIntelligence from "./EmotionalIntelligence";
import { Divider } from "antd";
import BeckAnxiety from "./BeckAnxiety";
import BeckDepression from "./BeckDepression";
import MOCAScale from "../Examination/PhysioScales/MOCAScale";
import { PsychologistReviewContext } from "../../Psychologist/Review/PsychologistReviewProvider";

const PsychologistScales = ({ form }) => {
  const { formData } = useContext(PsychologistReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.scales);
  }, [formData]);
  return (
    <>
      <EmotionalIntelligence context={PsychologistReviewContext} form={form} />
      <Divider />
      <BeckAnxiety context={PsychologistReviewContext} form={form} />
      <Divider />
      <BeckDepression context={PsychologistReviewContext} form={form} />
      <Divider />
      <MOCAScale context={PsychologistReviewContext} form={form} />
    </>
  );
};

export default PsychologistScales;
