import React, { useEffect, useState } from "react";
import { Button, Form, Radio, message, Input, Typography } from "antd";

import {
  getRasScaleAPI,
  rasScaleAPI,
  updateRasScaleAPI,
} from "../../../../../services/physio.services";

import unarousableSvg from "../../../../../assets/images/scalesImages/unarousable.svg";
import deepSedationSvg from "../../../../../assets/images/scalesImages/deepSedation.svg";
import moderateSedationSvg from "../../../../../assets/images/scalesImages/moderateSedation.svg";
import lightSedationSvg from "../../../../../assets/images/scalesImages/lightSedation.svg";
import drowsySvg from "../../../../../assets/images/scalesImages/drowsy.svg";
import alertAndCalmSvg from "../../../../../assets/images/scalesImages/alertAndCalm.svg";
import restlessSvg from "../../../../../assets/images/scalesImages/restless.svg";
import agitatedSvg from "../../../../../assets/images/scalesImages/agitated.svg";
import veryAgitatedSvg from "../../../../../assets/images/scalesImages/veryAgitated.svg";
import combativeSvg from "../../../../../assets/images/scalesImages/combative.svg";

import "./PhysioScales.css";

const RASScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const rassOptions = [
    {
      value: -5,
      label: "Unarousable",
      image: unarousableSvg,
    },
    { value: -4, label: "Deep Sedation", image: deepSedationSvg },
    { value: -3, label: "Moderate Sedation", image: moderateSedationSvg },
    { value: -2, label: "Light Sedation", image: lightSedationSvg },
    { value: -1, label: "Drowsy", image: drowsySvg },
    { value: 0, label: "Alert and Calm", image: alertAndCalmSvg },
    { value: 1, label: "Restless", image: restlessSvg },
    { value: 2, label: "Agitated", image: agitatedSvg },
    { value: 3, label: "Very Agitated", image: veryAgitatedSvg },
    { value: 4, label: "Combative", image: combativeSvg },
  ];

  const getInterpretation = (val) => {
    switch (val) {
      case -5:
        return "No response to voice or physical stimulation";
      case -4:
        return "No response to voice, but any movement to physical stimulation";
      case -3:
        return "Any movement(but no eye contact) to voice";
      case -2:
        return "Briefly (less than 10 seconds) awakens with eye contact to voice";
      case -1:
        return "Not fully alert, but has sustained (more than 10 seconds) awakening, with eye contact, to voice";
      case 0:
        return "Spontaneously pays attention to caregiver";
      case 1:
        return "Anxious or apprehensive but movements not aggresive or vigorous";
      case 2:
        return "Frequent nonpurposeful movement or patient-ventilator dyssynchrony";
      case 3:
        return "Pulls on or removes tube(s) or catheter(s) or has aggresive behaviour toward staff";
      case 4:
        return "Overtly combative or violent; Immediate danger to staff";
    }
  };

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      interpretation: getInterpretation(values?.rass_scale),
      ip_admission: admission,
    };

    try {
      if (Object.keys(formData).length !== 0) {
        await updateRasScaleAPI(scalesId?.rass, finalData);
      } else {
        const response = await rasScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, rass: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.rass) {
      try {
        const response = await getRasScaleAPI(admission, scalesId?.rass);
        setFormData(response.data);
        form.setFieldsValue(response.data);
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Form
          id="rass"
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
          form={form}
          initialValues={formData}
        >
          <Form.Item name="rass_scale">
            {/* <Typography>Richmond Agitation - Sedation Scale</Typography> */}
            <Radio.Group className="radio-group">
              {rassOptions.map((option) => (
                <Radio.Button
                  key={option.value}
                  value={option.value}
                  style={{
                    height: "100px",
                    width: "90px",
                    padding: 0,
                    margin: "0 2px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="visual-option">
                    {option.image && (
                      <img
                        src={option.image}
                        alt={option.label}
                        className="visual-image"
                      />
                    )}
                    <div
                      className="visual-label"
                      style={{ fontSize: "10px", lineHeight: "1.2" }}
                    >
                      {option.value}
                      <br />
                      {option.label}
                    </div>
                  </div>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <br />
          <br />
          <Form.Item name="comments" className="scales-comments-formitem">
            <Input.TextArea placeholder="Comments" rows={4} />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default RASScale;
