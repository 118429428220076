import React, { useEffect, useState } from "react";
import MotorSensoryScale from "./ASIAScaleComponents/MotorSensoryScale";
import { Button, Collapse, Form, message } from "antd";
import NeurologicalLevels from "./ASIAScaleComponents/NeurologicalLevels";
import DapVac from "./ASIAScaleComponents/DapVac";
import {
  asiaScaleApi,
  getAsiaScaleAPI,
  updateAsiaScaleAPI,
} from "../../../../../services/physio.services";
const { Panel } = Collapse;

const ASIAScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (scalesId?.asia) {
        await updateAsiaScaleAPI(scalesId?.asia, finalData);
      } else {
        const response = await asiaScaleApi(finalData);
        setScalesId((prev) => {
          const newScalesId = {
            ...prev,
            asia: response?.data?.id,
          };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      //   message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.asia) {
      getAsiaScaleAPI(admission, scalesId?.asia)
        .then((response) => {
          form.setFieldsValue(response.data);
          form.validateFields();
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const [scores, setScores] = useState({});
  const upperExtremityOptions = ["c5", "c6", "c7", "c8", "t1"];
  const lowerExtremityOptions = ["l2", "l3", "l4", "l5", "s1"];

  const onValuesChange = (changedValues, allValues) => {
    const updatedValues = { ...changedValues, ...allValues };
    if ("motor_sensory_scale" in changedValues) {
      const key = Object.keys(changedValues?.motor_sensory_scale);
      console.log(key, typeof key);
      switch (key[0]) {
        case "ltl":
          const ltl = Object.values(
            updatedValues?.motor_sensory_scale?.ltl ?? {}
          )
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ltl }));
          break;
        case "ppl":
          const ppl = Object.values(allValues?.motor_sensory_scale?.ppl ?? {})
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ppl }));
          break;
        case "ppr":
          const ppr = Object.values(allValues?.motor_sensory_scale?.ppr ?? {})
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ppr }));
          break;
        case "ltr":
          const ltr = Object.values(allValues?.motor_sensory_scale?.ltr || {})
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ltr }));
        case "left_motor":
          const leftUpperMotor = Object.entries(
            allValues?.motor_sensory_scale?.left_motor || {}
          )
            .filter(([key, value]) => upperExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          const leftLowerMotor = Object.entries(
            allValues?.motor_sensory_scale?.left_motor || {}
          )
            .filter(([key, value]) => lowerExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          setScores((prev) => ({ ...prev, leftUpperMotor, leftLowerMotor }));
          break;
        case "right_motor":
          const rightUpperMotor = Object.entries(
            allValues?.motor_sensory_scale?.right_motor || {}
          )
            .filter(([key, value]) => upperExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          const rightLowerMotor = Object.entries(
            allValues?.motor_sensory_scale?.right_motor || {}
          )
            .filter(([key, value]) => lowerExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          setScores((prev) => ({ ...prev, rightUpperMotor, rightLowerMotor }));
          break;
        default:
          console.log("Unexpected key", key);
      }
    }
  };
  return (
    <>
      <div className="cardless-scale">
        <Form
          id="asia-scale"
          form={form}
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
          onValuesChange={onValuesChange}
          style={{ width: "70%" }}
          layout="horizontal"
        >
          <MotorSensoryScale scores={scores} />
          <DapVac />
          <NeurologicalLevels />
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "Close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default ASIAScale;
