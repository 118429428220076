import React from "react";
import PhysiotherapistInitialAssessmentProvider from "../IpEMR/Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
import PhysiotherapistInitialInspection from "../IpEMR/Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialInspection";
import PhysioReviewFormProvider from "../IpEMR/Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";
import PhysiotherapistReview from "../IpEMR/Physiotherapist/PhysiotherapistReview/PhysiotherapistReview";

const PhysiotherapistIP = ({ patientData, params, refreshPage }) => {
  return (
    <>
      {params?.type === "ia" || params?.type === "landing" ? (
        <PhysiotherapistInitialAssessmentProvider>
          <PhysiotherapistInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.physio_initial_assessment}
            patientData={patientData}
          />
        </PhysiotherapistInitialAssessmentProvider>
      ) : params?.type === "review" ? (
        <PhysioReviewFormProvider>
          <PhysiotherapistReview
            reviewData={patientData?.physio_review}
            refreshPage={refreshPage}
            iAssessment={patientData?.physio_initial_assessment}
            patientData={patientData}
          />
        </PhysioReviewFormProvider>
      ) : (
        ""
      )}
    </>
  );
};

export default PhysiotherapistIP;
