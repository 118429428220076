import React from "react";
import { Table, Space, Tag } from "antd";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import dayjs from "dayjs";

const MyAppointmentsTable = ({ data }) => {
  const columns = [
    {
      title: "Slot",
      dataIndex: "appointment_time",
      key: "appointment_time",
      render: (value) => {
        return dayjs(value, "HH:mm:ss").format("hh:mm A");
      },
    },
    { title: "Patient ID", dataIndex: "patient_id", key: "patient_id" },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      render: (_, record) => (
        <Space>
          {capitalizeFirstLetter(record?.patient_first_name) +
            " " +
            capitalizeFirstLetter(record?.patient_last_name)}
        </Space>
      ),
    },
    {
      title: "Contact Number",
      dataIndex: "patient_mobile",
      key: "patient_mobile",
    },
    { title: "Age", dataIndex: "patient_age", key: "patient_age" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) =>
        value === "available" ? (
          <Tag color="green">Available</Tag>
        ) : (
          value === "reported" && <Tag color="orange">Reported</Tag>
        ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data?.data}
      bordered
      pagination={false}
    />
  );
};

export default MyAppointmentsTable;
