import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputNumber,
  message,
  Radio,
  Typography,
} from "antd";

import { tcmOptions } from "../../../Options/physioOptions";
import {
  getTcmScaleAPI,
  tcmScaleApi,
  updateTcmScaleAPI,
} from "../../../../../services/physio.services";
import TCMItems from "./TCMScaleComponents/TCMItems";

const TCMScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const calculateScore = () => {
    return Object.values(states).reduce((acc, cur) => acc + cur, 0);
  };

  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      score: aggregate,
      interpretation: interpretation,
      ip_admission: admission,
    };
    try {
      if (scalesId?.tcm) {
        await updateTcmScaleAPI(scalesId?.tcm, finalData);
      } else {
        const response = await tcmScaleApi(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, tcm: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.tcm) {
      getTcmScaleAPI(admission, scalesId?.tcm)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          console.log("tcm response", formData);
          setStates({
            sitting_balance: formData?.sitting_balance,
            selective_movement_control: formData?.selective_movement_control,
            sitting_to_standing: formData?.sitting_to_standing,
          });
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const aggregate = calculateScore() || 0;
  const interpretation =
    aggregate === 58 ? "Normal Trunk Balance" : "Poor Trunk Balance";

  return (
    <>
      <div className="cardless-scale">
        <Typography className="score-counter">
          {aggregate}
          <br />—<br />
          58
          <div className="score-string">{interpretation}</div>
        </Typography>
        <Card style={{ width: "600px" }}>
          <Form
            id="tcm-scale"
            onFinish={(values) => handleSubmitWithAction(values, "continue")}
            form={form}
            layout="vertical"
          >
            <TCMItems handleChange={handleChange} />
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default TCMScale;
