import React, { useContext } from "react";
import { NurseInitialContext } from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import { Col, Row } from "antd";
import NurseHistorySummary from "../../InspectionSummaries/HistorySummary/NurseHistorySummary";
import NurseFunctionalAssessmentSummary from "../../InspectionSummaries/FunctionalExaminationSummary/NurseFunctionalAssessmentSummary";
import NurseExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/NurseExaminationSummary";
import NurseSpecialCareRequirementSummary from "../../InspectionSummaries/NurseSpecialCareRequirementSummary/NurseSpecialCareRequirementSummary";

const NurseInitialInspectionSummary = () => {
  const { formData } = useContext(NurseInitialContext);
  return (
    <>
      <Row className="summaries" gutter={[16, 16]}>
        <Col
          className="insp-card"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
        >
          {formData?.history && <NurseHistorySummary formData={formData} />}
        </Col>

        <Col
          className="insp-card"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
        >
          {formData?.functional_status && (
            <NurseFunctionalAssessmentSummary formData={formData} />
          )}
        </Col>
      </Row>

      <Row
        className="summaries"
        gutter={[16, 16]}
        style={{ marginTop: "16px" }}
      >
        <Col
          className="insp-card"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
        >
          {formData?.examination && (
            <NurseExaminationSummary formData={formData} />
          )}
        </Col>

        <Col
          className="insp-card"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
        >
          {formData?.special_care && (
            <NurseSpecialCareRequirementSummary formData={formData} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default NurseInitialInspectionSummary;
