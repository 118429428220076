import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PhysioReviewContext } from "./PhysioReviewFormProvider";
import {
  getLatestPhysioReviewAPI,
  getPhysioInspectionAPI,
  getPhysioReviewAPI,
  physioReviewAPI,
  updatePhysioReviewAPI,
} from "../../../../services/physio.services";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import PhysioScales from "../../InspectionForms/Examination/PhysioScales/PhysioScales";
import PhysioFunctionalDiagnosisReview from "../../InspectionForms/FunctionalDiagnosis/PhysioFunctionalDiagnosisReview";
import PhysioTreatmentPlanReview from "../../InspectionForms/TreatmentPlan/PhysioTreatmentPlanReview";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";

const PhysioReviewForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  iAssessment,
  addMode,
  setAddMode,
  setActiveSegment,
  reviewData,
  setDailyReviewRefresh,
  dailyRefresh,
  segmentData,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [diagnosisForm] = Form.useForm();
  const [scalesForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const { formData, setFormData } = useContext(PhysioReviewContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);
  const newReview = reviewData?.length === 0;
  const reviewId = reviewData?.[reviewData.length - 1]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;
  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });
  const [scalesId, setScalesId] = useState({});
  const [scaleTab, setScaleTab] = useState("1");

  const handleSubmit = async (values) => {
    try {
      if (editId || newReviewId) {
        await updatePhysioReviewAPI(editId || newReviewId, values);
        setDailyReviewRefresh();
      } else {
        await physioReviewAPI(values);
        refreshPage();
        setAddMode(false);
        setDailyReviewRefresh();
      }

      message.success("Form submitted successfully");

      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onDiagnosisSubmit = (values) => {
    handleSubmit({
      ...formData,
      functional_diagnosis: values,
      admission_id: params?.patient,
    });
  };
  const onScalesSubmit = (values) => {
    handleSubmit({
      ...formData,
      scales: values,
      admission_id: params?.patient,
    });
  };
  const onTreatmentSubmit = (values) => {
    handleSubmit({
      ...formData,
      treatment_plan: values,
      admission_id: params?.patient,
    });
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getPhysioReviewAPI(editId);
        setFormData(response?.data?.data);
        setScalesId(response?.data?.data?.scales);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestPhysioReviewAPI(params?.patient);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getPhysioInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return diagnosisForm.submit();
      case "2":
        return scalesForm.submit();
      case "3":
        return treatmentForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      switch (activeTab) {
        case "1":
          diagnosisForm.validateFields().then((values) => {
            setIsDirty(false);
            onDiagnosisSubmit(values);
            setActiveTab(key);
          });
          break;
        case "2":
          scalesForm.validateFields().then((values) => {
            setIsDirty(false);
            onScalesSubmit(values);
            setActiveTab(key);
          });
          break;
        case "3":
          treatmentForm.validateFields().then((values) => {
            setIsDirty(false);
            onTreatmentSubmit(values);
            setActiveTab(key);
          });
          break;
        default:
          setActiveTab(key);
      }
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <div>
      <Drawer
        open={visible}
        width="100%"
        className="physioreview-drawer"
        // title={
        //   <Row>
        //     <Col span={12} className="flex-start">
        //       <Typography
        //         style={{
        //           marginLeft: "12px",
        //           fontWeight: "400",
        //           fontSize: "14px",
        //         }}
        //       >
        //         {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
        //         ({reviewDate})
        //       </Typography>
        //     </Col>
        //     <Col span={12} className="flex-end">
        //       <CloseOutlined
        //         onClick={() => {
        //           Modal.confirm({
        //             title: "Confirmation",
        //             content: "Are you sure you want to close?",
        //             onOk: () => {
        //               setVisible(false);
        //             },
        //           });
        //         }}
        //       />
        //     </Col>
        //   </Row>
        // }
        footer={
          activeTab !== "2" ? (
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  getFormKey();
                  const nextTab = (parseInt(activeTab) + 1).toString();
                  if (activeTab === "3") {
                    setVisible(false);
                  } else {
                    setActiveTab(nextTab);
                  }
                }}
              >
                {activeTab === "3" ? "Done" : " Save & Proceed"}
              </Button>
              {"  "}
              {activeTab !== "3" && (
                <Button
                  onClick={() => {
                    getFormKey();
                    setVisible(false);
                  }}
                  type="primary"
                >
                  Save & Close
                </Button>
              )}
            </div>
          ) : // <div style={{ textAlign: "Right" }}>
          //   <Button onClick={() => setActiveTab("3")}>Save & Close</Button>{" "}
          //   <Button
          //     onClick={() =>
          //       setScaleTab((prev) => (parseInt(prev) + 1).toString())
          //     }
          //     type="primary"
          //   >
          //     Save & Continue
          //   </Button>
          // </div>
          null
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(reviewData[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Row>
          <Col span={8}>
            <Space>
              <LeftOutlined
                onClick={() => {
                  Modal.confirm({
                    title: "Confirmation",
                    content: "Are you sure you want to close?",
                    onOk: () => {
                      setVisible(false);
                    },
                  });
                }}
                style={{}}
              />
              <Typography
                style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
                {/* ({reviewDate}) */}
              </Typography>
              {patientData?.admission?.bed?.bed_no && (
                <Tag color="orange">
                  <Image src={bedImg} preview={false} />
                  {patientData?.admission?.bed?.bed_no}
                </Tag>
              )}
            </Space>
            <br />
            <Space style={{ marginTop: "10px" }}>
              <Tag>
                {patientData?.admission?.patient?.age}{" "}
                {patientData?.admission?.patient?.gender}
              </Tag>
              <Tag>IP ID: {patientData?.admission?.id}</Tag>
            </Space>
          </Col>
          <Col span={12}>
            <Tabs
              activeKey={activeTab}
              items={[
                { key: "1", label: "Functional Diagnosis" },
                { key: "2", label: "Scales" },
                { key: "3", label: "Treatment Plan" },
              ]}
              onChange={handleTabChange}
            />
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Typography>({reviewDate})</Typography>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0px" }} />
        {/* <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Functional Diagnosis" key="1"> */}
        {activeTab === "1" && (
          <Form
            id="daily-review-functional-diagnosis"
            form={diagnosisForm}
            layout="vertical"
            onFinish={onDiagnosisSubmit}
            onValuesChange={onFormValuesChange}
            initialValues={formData}
            className="ip-forms"
          >
            <PhysioFunctionalDiagnosisReview form={diagnosisForm} />
          </Form>
        )}
        {/* </TabPane> */}
        {/* <TabPane tab="Scales" key="2"> */}
        {/* <Form
              id="daily-review-scales-form"
              form={scalesForm}
              layout="vertical"
              onFinish={onScalesSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            > */}
        {activeTab === "2" && (
          <PhysioScales
            admission={params?.patient}
            reviewData={reviewData}
            editId={editId}
            onScalesSubmit={onScalesSubmit}
            scalesId={scalesId}
            setScalesId={setScalesId}
            setActiveTab={setActiveTab}
            scaleTab={scaleTab}
            setScaleTab={setScaleTab}
            setVisible={setVisible}
          />
        )}
        {/* </Form> */}
        {/* </TabPane> */}
        {/* <TabPane tab="Treatment Plan" key="3"> */}
        {activeTab === "3" && (
          <Form
            id="daily-review-treatment-plan"
            form={treatmentForm}
            layout="vertical"
            onFinish={onTreatmentSubmit}
            onValuesChange={onFormValuesChange}
            initialValues={formData}
            className="ip-forms"
          >
            <PhysioTreatmentPlanReview form={treatmentForm} />
          </Form>
        )}
        {/* </TabPane> */}
        {/* </Tabs> */}
      </Drawer>
    </div>
  );
};

export default PhysioReviewForm;
