import { Card, Form, Radio } from "antd";
import React from "react";
import { yesNoOptions } from "../../../../Options/commonOptions";

const DapVac = () => {
  return (
    <Card>
      <Form.Item label="DAP" name={"dap"}>
        <Radio.Group options={yesNoOptions} />
      </Form.Item>
      <Form.Item label="VAC" name={"vac"}>
        <Radio.Group options={yesNoOptions} />
      </Form.Item>
    </Card>
  );
};

export default DapVac;
