import React, { useState, useRef } from "react";
import {
  Col,
  Collapse,
  Empty,
  Row,
  Space,
  Tag,
  Image,
  Typography,
  message,
  Form,
  Button,
  Input,
  DatePicker,
  Popover,
} from "antd";
import dayjs from "dayjs";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import bedIcon from "../../../../assets/receptionistIcons/bedIcon.svg";
import roomIcon from "../../../../assets/receptionistIcons/roomIcon.svg";
import tagStyles from "../../../../styles/Custom/Tag.module.css";
import useQuery from "../../../../hooks/useQuery";
import {
  changeIpBedApi,
  updateIpBedApi,
} from "../../../../services/receptionist.services";
import { formatToINR } from "../../../../helpers/utility";

const BedCards = ({
  beds,
  patientData,
  refreshPage,
  refreshBedData,
  editBed,
  setEditBed,
  setSwitchBed,
}) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [visiblePopover, setVisiblePopover] = useState(null); // Track which popover is visible
  const [editListData, setEditListData] = useState(null);
  const bedId = patientData?.admission?.bed?.id;

  const onFinish = (values) => {
    const combinedDateTime = dayjs(
      `${values.date.format("YYYY-MM-DD HH:mm")} `
    );
    const formData = {
      bed_start_date: combinedDateTime.format(),
      bed_remarks: values.remarks,
      bed: editListData?.id,
    };

    {
      if (editBed || !bedId) {
        updateIpBedApi(params?.patient, formData)
          .then((res) => {
            message.success(res?.data?.message);
            setVisiblePopover(null);
            refreshPage();
            refreshBedData();
            form.resetFields();
            setEditBed(false);
          })
          .catch((err) => {
            message.error(err?.response?.data?.message);
          });
      } else {
        changeIpBedApi(params?.patient, formData)
          .then((res) => {
            message.success(res?.data?.message);
            setVisiblePopover(null);
            refreshPage();
            refreshBedData();
            form.resetFields();
            setSwitchBed(false);
          })
          .catch((err) => {
            message.error(err?.response?.data?.message);
          });
      }
    }
  };

  const content = (
    <div style={{ width: "480px", padding: "20px 0px 12px" }}>
      <Form
        ref={formRef}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          date: dayjs().startOf("minute"),
        }}
      >
        <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker
            defaultValue={dayjs(dayjs().format("YYYY-MM-DD HH:mm"))}
            className={inputStyles.mdDateInput}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item label="Remarks" name="remarks">
          <Input.TextArea
            style={{ width: "220px" }}
            className={inputStyles.mdInput}
          />
        </Form.Item>
        <div className="flex-end" style={{ gap: "12px" }}>
          <Button
            type="primary"
            ghost
            className={btnStyles.mdBtn}
            onClick={() => setVisiblePopover(null)} // Close popover when cancel is clicked
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" className={btnStyles.mdBtn}>
            Save
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <>
      {beds?.map((item, index) => (
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={0}
          data={item}
          className="custom-collapse"
        >
          <Collapse.Panel
            header={
              <Space>
                {item?.floor_no}
                <Tag color="green">Available Beds : {item?.beds_available}</Tag>
                <Tag color="blue">Total Beds : {item?.total_no_of_beds}</Tag>
              </Space>
            }
            key={index}
            style={{ backgroundColor: "#fff" }}
          >
            <Row>
              {item?.bed_data?.length > 0 ? (
                item?.bed_data?.map((bed) => (
                  <Col
                    key={bed.id} // Unique key for each bed
                    span={{ xs: 24, sm: 12, md: 6, lg: 4 }}
                    className="bed-tag-management"
                  >
                    <Popover
                      trigger="click"
                      content={content}
                      placement="bottom"
                      title={
                        <>
                          Bed No: {editListData?.bed_no}
                          <br />
                          <Space>
                            <Tag color="purple" className={tagStyles?.mdRndTag}>
                              Room No : {editListData?.room__room_no}
                            </Tag>
                            <Tag color="blue" className={tagStyles?.mdRndTag}>
                              Type : {editListData?.room__room_type__name}
                            </Tag>
                            <Tag className={tagStyles?.mdRndTag}>
                              Cash Price :
                              {formatToINR(editListData?.cash_price)}
                            </Tag>
                          </Space>
                        </>
                      }
                      visible={visiblePopover === bed.id} // Make sure only one popover is visible at a time
                      onVisibleChange={(vis) => {
                        if (vis) {
                          setEditListData(bed); // Set the current bed data
                          setVisiblePopover(bed.id); // Set this specific popover to visible
                        } else {
                          setVisiblePopover(null); // Close the popover
                        }
                      }}
                    >
                      <Tag.CheckableTag
                        className="tag"
                        style={{
                          backgroundColor:
                            bed?.status && bed?.id === bedId
                              ? "#b9f0c8"
                              : !bed?.status
                              ? ""
                              : "#D9D9D9",
                          border:
                            bed?.status && bed?.id === bedId
                              ? "1px solid #578D66"
                              : !bed?.status
                              ? "1px solid #578D66"
                              : "",
                          pointerEvents: bed?.status ? "none" : "",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                        onClick={() => setVisiblePopover(bed.id)} // Show popover on bed click
                      >
                        <Space
                          align="center"
                          justify="center"
                          style={{ height: "60px" }}
                        >
                          <Image
                            src={bedIcon}
                            alt="bed-icon"
                            preview={false}
                            width={24}
                          />
                          <Typography>{bed?.bed_no}</Typography>
                          <Image
                            src={roomIcon}
                            alt="room-icon"
                            preview={false}
                            width={24}
                          />
                          <Typography>{bed?.room__room_no}</Typography>
                        </Space>
                      </Tag.CheckableTag>
                    </Popover>
                  </Col>
                ))
              ) : (
                <Empty description="No beds in this floor" />
              )}
            </Row>
          </Collapse.Panel>
        </Collapse>
      ))}
    </>
  );
};

export default BedCards;
