import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Form, message, Space, Tag, Image, Col, Button } from "antd";
import {
  RedoOutlined,
  BorderOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  EditOutlined,
  CloseOutlined,
  CaretRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "../../ipforms.css";

import NurseData from "./NurseData";
import ReviewData from "./ReviewData";
import useQuery from "../../../../hooks/useQuery";
import CustomCard from "../../../../blocks/Cards/CustomCard";
import nurse from "../../../../assets/careItemIcons/nurse.png";
import useTwitterTimestamp from "../../../../hooks/useTwitterTimestamp";
import {
  createOrderSheetApi,
  updateOrderSheetApi,
} from "../../../../services/orderSheet.services";

function CareItemForm(props, ref) {
  const {
    getData,
    formData,
    formType,
    formItems,
    formState,
    iaAction,
    setFormState,
    isIaCareItem,
    reviewFormItems,
    updateIACareItemRecord,
  } = props;
  const query = useQuery();
  const [form] = Form.useForm();
  // This should be below of useForm()
  useImperativeHandle(ref, () => ({ onFinish }));
  const [loading, setLoading] = useState(false);
  const orderDate = useTwitterTimestamp(formData?.order_date);
  const completedDate = useTwitterTimestamp(formData?.completed_date);
  const [nurseModalStatus, setNurseModalStatus] = useState(false);
  const [reviewModalStatus, setReviewModalStatus] = useState(false);

  useEffect(() => {
    if (formData) form.setFieldsValue(formData?.data);
  }, [formData, form]);

  async function updateAction(action) {
    setLoading(true);
    try {
      const response = await updateOrderSheetApi(
        {
          action,
          care_type: formType,
          admission_id: query?.patient,
        },
        formData?.id
      );

      await getData();
      message.success(response.data.message);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function onFinish(values, iaFormType = null) {
    setLoading(true);
    try {
      const response =
        (!formState?.editingIndex && (formState?.adding || true)) ||
        isIaCareItem
          ? await createOrderSheetApi({
              data: values,
              admission_id: query?.patient,
              care_type: isIaCareItem ? iaFormType : formType,
              action: "start",
            })
          : await updateOrderSheetApi(
              {
                data: values,
                care_type: formType,
                admission_id: query?.patient,
              },
              formData?.id
            );
      if (typeof getData === "function") await getData();
      if (typeof setFormState === "function")
        setFormState({ adding: false, editingIndex: null });
      message.success(response.data.message);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const renderActionButton = (icon, label, onClick, disabled = false) => (
    <Button
      size="small"
      style={{ height: "auto" }}
      onClick={onClick}
      loading={disabled}
      type={
        isIaCareItem &&
        iaAction?.[formType]?.status === "continue" &&
        label === "Continue"
          ? "primary"
          : isIaCareItem &&
            iaAction?.[formType]?.status === "discard" &&
            label === "Discard"
          ? "default"
          : "text"
      }
    >
      {console.log(iaAction)}
      <Space direction="vertical" size="small" className="icon-text-segment">
        {icon}
        <span style={{ fontSize: "12px" }}>{label}</span>
      </Space>
    </Button>
  );

  const actions = [];

  if (formState?.adding && !formData) {
    // Delete Action while creating new careitem
    actions.push(
      renderActionButton(
        <DeleteOutlined style={{ color: "red", fontSize: "12px" }} />,
        "Delete",
        () => setFormState((prev) => ({ ...prev, adding: false }))
      )
    );
  }

  if (formData && !isIaCareItem) {
    if (formData?.action !== "stop") {
      if (formState?.editingIndex !== formData?.id) {
        // Edit Action to update
        actions.push(
          renderActionButton(
            <EditOutlined style={{ fontSize: "14px" }} />,
            "Edit",
            () =>
              setFormState((prev) => ({
                ...prev,
                editingIndex: formData?.id,
              }))
          )
        );
      } else {
        // Submit Action while update
        actions.push(
          renderActionButton(
            <CheckOutlined style={{ fontSize: "14px" }} />,
            "Done",
            () => form.submit(),
            loading
          )
        );

        // Cancel Action while update
        actions.push(
          renderActionButton(
            <CloseOutlined style={{ fontSize: "14px" }} />,
            "Cancel",
            () =>
              setFormState((prev) => ({
                ...prev,
                editingIndex: null,
              })),
            loading
          )
        );
      }
    }
  } else if (isIaCareItem) {
    // Discard Action from Initial Assessment
    actions.push(
      renderActionButton(<CloseOutlined />, "Discard", () =>
        updateIACareItemRecord({
          [formType]: {
            status: "discard",
            data: form?.getFieldsValue(),
          },
        })
      )
    );

    // Continue Action from Initial Assessment
    actions.push(
      renderActionButton(
        <CaretRightOutlined />,
        "Continue",
        () =>
          updateIACareItemRecord({
            [formType]: {
              status: "continue",
              data: form?.getFieldsValue(),
            },
          }),
        loading
      )
    );
  } else {
    // Form submit action
    actions.push(
      renderActionButton(
        <CaretRightOutlined style={{ fontSize: "14px" }} />,
        "Start",
        () => form.submit(),
        loading
      )
    );
  }

  if (formData && !isIaCareItem) {
    // Stop Action
    actions.push(
      formData?.action !== "stop" &&
        formState?.editingIndex !== formData?.id &&
        renderActionButton(
          <BorderOutlined style={{ color: "red", fontSize: "12px" }} />,
          "Stop",
          () => updateAction("stop"),
          loading
        )
    );

    // Replace Action
    actions.push(
      ["feeding_tube", "urinary_catheter_care"]?.includes(formType) &&
        formData?.action !== "stop" &&
        renderActionButton(
          <RedoOutlined style={{ color: "orange", fontSize: "12px" }} />,
          "Replace",
          () => updateAction("replace"),
          loading
        )
    );

    // Nurse Review Data
    actions.push(
      renderActionButton(
        <Image
          preview={false}
          src={nurse}
          style={{
            height: "14px",
            width: "auto",
            marginBottom: "4px",
            marginRight: "4px",
          }}
        />,
        "Nurse",
        () => setNurseModalStatus(true)
      )
    );

    // Daily Review Data
    actions.push(
      ["feeding_tube", "ostomy_care"].includes(formType) &&
        renderActionButton(
          <FileSearchOutlined style={{ fontSize: "14px" }} />,
          "Review",
          () => setReviewModalStatus(true)
        )
    );
  }

  return (
    <Col span={12}>
      <Form form={form} layout="vertical" name={formType} onFinish={onFinish}>
        <CustomCard
          size="small"
          className={
            formData?.action === "stop" && "care-item-card-stop-styles"
          }
          extra={
            <Space>
              {formData?.completed_date && (
                <Tag color="red">Stopped {completedDate}</Tag>
              )}
              {formData?.submitted_by && (
                <Tag color="geekblue">
                  Advised by {formData?.submitted_by?.first_name || "-"}{" "}
                  {orderDate}
                </Tag>
              )}
            </Space>
          }
          actions={actions.filter((item) => item)}
        >
          {formItems}
        </CustomCard>
      </Form>
      {reviewModalStatus && (
        <ReviewData
          data={[]}
          formType={formType}
          formData={formData}
          reviewFormItems={reviewFormItems}
          reviewModalStatus={reviewModalStatus}
          setReviewModalStatus={setReviewModalStatus}
        />
      )}
      {nurseModalStatus && (
        <NurseData
          id={formData?.id}
          data={[]}
          nurseModalStatus={nurseModalStatus}
          setNurseModalStatus={setNurseModalStatus}
        />
      )}
    </Col>
  );
}

export default forwardRef(CareItemForm);
