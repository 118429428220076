import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getBillsListApi } from "../../../services/receptionist.services";
import btnStyles from "../../../styles/Custom/Button.module.css";
import BillingTable from "./BillingTable";
import { debounce } from "../../../helpers/utility";

const Billing = () => {
  const navigate = useNavigate();
  const [billData, setBillData] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    let params = {
      page: page,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    const queryString = new URLSearchParams(params);
    getBillsListApi(queryString)
      .then((res) => {
        setBillData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [searchQuery, page, refresh]);

  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };
  return (
    <Row gutter={[12, 12]}>
      <Col span={24} className="flex-between">
        <Input.Search
          style={{ width: "30%", marginBottom: "10px" }}
          size="large"
          placeholder="Search for patient"
          onChange={debounce(onContactSearch, 300)}
        ></Input.Search>
        <Button
          onClick={() => navigate(`/receptionist/billing/?patient=true`)}
          type="primary"
          className={btnStyles.mdBtn}
        >
          <PlusOutlined /> OP Billing
        </Button>
      </Col>

      <BillingTable
        data={billData}
        page={page}
        setPage={setPage}
        loading={loading}
        refresh={refreshPage}
      />
      {billData?.data?.length > 50 && (
        <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={50}
            total={billData?.total_pages}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default Billing;
