import React, { useState, useCallback } from "react";
import { AutoComplete, Form, Input, Row, Col, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { capitalizeFirstLetter, debounce } from "../../../helpers/utility";
import { getPractitionerSearchApi } from "../../../services/receptionist.services";
import { validateConfig } from "../../../config/validate.config";

const PractitionerSearch = ({
  setSelectedPractitioner,
  practitionerText,
  setPractitionerText,
  facilityId,
  type,
}) => {
  const [data, setData] = useState(null);

  const setSearchOptions = (data) => {
    const searchOptions = data.map((options) => {
      return {
        value: `${capitalizeFirstLetter(
          options?.first_name
        )} ${capitalizeFirstLetter(options?.last_name)} (Doctor)`,
        key: type ? options?.user__id : options?.id,
        label: (
          <Row>
            <Col span={10}>
              <span>
                {capitalizeFirstLetter(options?.first_name)}{" "}
                {capitalizeFirstLetter(options?.last_name)}
                {"  "}
                {<Tag color="blue">{options?.role}</Tag>}
              </span>
            </Col>
          </Row>
        ),
      };
    });

    return searchOptions;
  };

  const handleSearch = (searchedValue) => {
    console.log(searchedValue);
    getPractitionerSearchApi(
      `query=${searchedValue}&facility=${facilityId}&role=Doctor`
    )
      .then((res) => {
        setData(setSearchOptions(res?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), [
    facilityId,
  ]);

  const handleSelect = (value, option) => {
    setPractitionerText(value);
    setSelectedPractitioner(option?.key);
  };

  return (
    <Form.Item
      name="name"
      label={type ? type : "Choose Practitioner"}
      rules={validateConfig.isRequired("Practitioner")}
    >
      <AutoComplete
        options={data}
        className="search"
        onSearch={optimizedSearch}
        onSelect={handleSelect}
        onChange={(value) => {
          setPractitionerText(value);
          optimizedSearch(value);
        }}
        value={practitionerText}
        style={{ width: "300px", marginBottom: "10px" }}
      >
        <Input
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search Practitioner"
          className={inputStyles.mdInput}
          style={{ width: "300px" }}
        />
      </AutoComplete>
    </Form.Item>
  );
};

export default PractitionerSearch;
