import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import ClinicalCard from "./ClinicalCard";
import Graphs from "./Graphs/Graphs";
import ProgressData from "./ProgressData";
import { getPatientInfoApi } from "../../../../services/patient.services";
import useQuery from "../../../../hooks/useQuery";
import SummaryMedicines from "./SummaryMedicines";
import SummaryInvestigations from "./SummaryInvestigations";
import "./Landing.css";
import SpecialAreas from "./SpecialAreas";
const Landing = () => {
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useQuery();
  useEffect(() => {
    setLoading(true);
    getPatientInfoApi(params?.patient)
      .then((res) => {
        setPatientData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params?.patient]);
  return (
    <Row gutter={[8, 2]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <ClinicalCard data={patientData?.data?.clinical_diagnosis} />
        <ProgressData data={patientData?.data?.progress_notes} />
        <SummaryMedicines data={patientData?.data?.medicines} />
        <SummaryInvestigations data={patientData?.data?.investigations} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Graphs data={patientData?.data} />
        <SpecialAreas data={patientData?.data?.care_items} />
      </Col>
    </Row>
  );
};

export default Landing;
