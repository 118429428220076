import React from "react";
import { Form, InputNumber, Row, Col, Typography } from "antd";
const { Text } = Typography;

const VitalsAdmission = () => {
  return (
    <>
      <Text strong>Vitals at the time of Admission</Text>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="temperature" label="Temperature">
              <InputNumber
                min={94}
                max={106}
                addonAfter="Degrees F"
                style={{ width: "55%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="pulse_rate" label="Pulse Rate">
              <InputNumber
                min={40}
                max={160}
                addonAfter="beats/min"
                style={{ width: "55%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="respiratory_rate" label="Respiratory Rate">
              <InputNumber
                min={6}
                max={60}
                addonAfter="breaths/min"
                style={{ width: "55%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="systolic_bp" label="Systolic BP">
              <InputNumber
                min={60}
                max={200}
                addonAfter="mmHg"
                style={{ width: "55%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="diastolic_bp" label="Diastolic BP">
              <InputNumber
                min={40}
                max={140}
                addonAfter="mmHg"
                style={{ width: "55%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="spo2" label="SpO2">
              <InputNumber
                min={75}
                max={100}
                addonAfter="%"
                style={{ width: "55%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default VitalsAdmission;
