import React, { useContext, useEffect } from "react";
import { PhysioReviewContext } from "./PhysioReviewFormProvider";
import { getPhysioReviewAPI } from "../../../../services/physio.services";
import { Col, message, Row } from "antd";
import PhysioFunctionalDiagnosisSummary from "../../InspectionSummaries/FunctionalExaminationSummary/PhysioFunctionalDiagnosisSummary";
import PhysioTreatmentPlanSummary from "../../InspectionSummaries/TreatmentPlan/PhysioTreatmentPlanSummary";
import PhysioScalesReviewSummary from "../../InspectionSummaries/Scales/PhysioScalesReviewSummary";

const PhysioReviewSummary = ({ editId, dailyRefresh }) => {
  const { formData, setFormData } = useContext(PhysioReviewContext);
  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getPhysioReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);
  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col span={12}>
        {formData?.functional_diagnosis && (
          <PhysioFunctionalDiagnosisSummary formData={formData} />
        )}
        {formData?.treatment_plan && (
          <PhysioTreatmentPlanSummary formData={formData} />
        )}
      </Col>
      <Col span={12}>
        {formData?.scales && <PhysioScalesReviewSummary formData={formData} />}
      </Col>
    </Row>
  );
};

export default PhysioReviewSummary;
