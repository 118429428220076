import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import DoctorLayout from "../../Layout/DoctorLayout";
import PatientsIP from "../../PatientsIP/PatientsIP";
import DoctorAppointments from "../../DoctorView/Appointments/DoctorAppointments";
import PatientCards from "../../DoctorView/PatientCards";
import PatientTypeContextProvider from "../../Layout/DoctorLayout/PatientTypeContextProvider";

const CommonView = () => {
  const location = useLocation();
  const query = useParams();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    if (userDetails?.currFacility) {
      console.log(userDetails?.currFacility === 1 ? "Hello" : "Hai");
    }
  }, [userDetails?.currFacility]);

  return (
    <>
      {query?.tab === "appointments" ? (
        <DoctorAppointments />
      ) : (
        <PatientTypeContextProvider>
          {params?.patient ? (
            <DoctorLayout showPatientSegment={true}>
              <PatientsIP />
            </DoctorLayout>
          ) : (
            <DoctorLayout showPatientSegment={true} header={true}>
              <PatientCards />
            </DoctorLayout>
          )}
        </PatientTypeContextProvider>
      )}
    </>
  );
};

export default CommonView;
