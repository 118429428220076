import React, { useContext, useState } from "react";
import {
  psychologistInspectionAPI,
  updatePsychologistInspectionAPI,
} from "../../../../services/psychologist.services";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { useLocation } from "react-router-dom";
import PsychologistPatientDemographics from "../../InspectionForms/HistoryForm/PsychologistPatientDemographics";
import PreviousCounselling from "../../InspectionForms/PreviousCounselling/PreviousCounselling";
import Symptoms from "../../InspectionForms/Symptoms/Symptoms";
import PsychologistDiagnosis from "../../InspectionForms/Symptoms/PsychologistDiagnosis";
import PsychologistTherapiesSuggested from "../../InspectionForms/Therapy/PsychologistTherapiesSuggested";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { PsychologistInitialContext } from "./PsychologistInitialAssessmentProvider";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { getLabel } from "../../InspectionSummaries/constructTextFromValues";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
const PsychologistInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
  patientData,
  setConfirmModal,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  // const [historyForm] = Form.useForm();
  const [demographicForm] = Form.useForm();

  const [counsellingForm] = Form.useForm();
  const [symptomsForm] = Form.useForm();
  const [diagnosisForm] = Form.useForm();
  const [therapyForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(PsychologistInitialContext);
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await updatePsychologistInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await psychologistInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success("Form submitted successfully");
      if (activeTab === "5") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      console.log(error, "Error while submitting");
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onDemographicSubmit = (values) => {
    handleSubmit({
      patient_demographics: values,
      admission_id: params?.patient,
    });
  };

  const onCounsellingSubmit = (values) => {
    handleSubmit({
      previous_counselling: values,
      admission_id: params?.patient,
    });
  };
  const onSymptomsSubmit = (values) => {
    handleSubmit({
      symptoms: values,
      admission_id: params?.patient,
    });
  };
  const onDiagnosisSubmit = (values) => {
    handleSubmit({
      psychology_diagnosis: values,
      admission_id: params?.patient,
    });
  };
  const onTherapySubmit = (values) => {
    handleSubmit({
      therapies_suggested: values,
      admission_id: params?.patient,
    });
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return demographicForm.submit();
      case "2":
        return counsellingForm.submit();
      case "3":
        return symptomsForm.submit();
      case "4":
        return diagnosisForm.submit();
      case "5":
        return therapyForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              demographicForm.validateFields().then((values) => {
                setIsDirty(false);
                onDemographicSubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              counsellingForm.validateFields().then((values) => {
                setIsDirty(false);
                onCounsellingSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              symptomsForm.validateFields().then((values) => {
                setIsDirty(false);
                onSymptomsSubmit(values);
                setActiveTab(key);
              });
            case "4":
              diagnosisForm.validateFields().then((values) => {
                setIsDirty(false);
                onSymptomsSubmit(values);
                setActiveTab(key);
              });
            case "5":
              therapyForm.validateFields().then((values) => {
                setIsDirty(false);
                onSymptomsSubmit(values);
                setActiveTab(key);
              });
            default:
              setActiveTab(key);
          }
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Space>
            <LeftOutlined onClick={() => setConfirmModal(true)} />{" "}
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
            </Typography>
            {patientData?.admission?.bed?.bed_no && (
              <Tag color="orange">
                <Image src={bedImg} preview={false} />{" "}
                {patientData?.admission?.bed?.bed_no}
              </Tag>
            )}
          </Space>
          <br />
          <Space style={{ marginTop: "10px" }}>
            <Tag>
              {patientData?.admission?.patient?.age}{" "}
              {patientData?.admission?.patient?.gender}
            </Tag>
            <Tag>IP ID: {patientData?.admission?.id}</Tag>
          </Space>
        </Col>
        <Col span={18}>
          {" "}
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "Patient Demographics" },
              { key: "2", label: "Previous Counselling / Psychotherapy" },
              { key: "3", label: "Symptoms / Impressions" },
              { key: "4", label: "Psychology Diagnosis" },
              { key: "5", label: "Therapies Suggested" },
            ]}
            onChange={handleTabChange}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0px" }} />

      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div style={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
          {activeTab === "1" && (
            <Form
              id="patient_demographics-form"
              form={demographicForm}
              layout="vertical"
              onFinish={onDemographicSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PsychologistPatientDemographics
                form={demographicForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="previous-counselling-form"
              form={counsellingForm}
              layout="vertical"
              onFinish={onCounsellingSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PreviousCounselling
                form={counsellingForm}
                formData={formData}
                context={PsychologistInitialContext}
              />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="symptoms-form"
              form={symptomsForm}
              layout="vertical"
              onFinish={onSymptomsSubmit}
              onValuesChange={onFormValuesChange}
              // initialValues={formData}
              className="ip-forms"
            >
              <Symptoms form={symptomsForm} formData={formData} />
            </Form>
          )}

          {activeTab === "4" && (
            <Form
              id="psychology-diagnosis-form"
              form={diagnosisForm}
              layout="vertical"
              onFinish={onDiagnosisSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PsychologistDiagnosis
                form={diagnosisForm}
                context={PsychologistInitialContext}
              />
            </Form>
          )}
          {activeTab === "5" && (
            <Form
              id="therapies-suggested-form"
              form={therapyForm}
              layout="vertical"
              onFinish={onTherapySubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <PsychologistTherapiesSuggested
                form={therapyForm}
                context={PsychologistInitialContext}
              />
            </Form>
          )}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "5" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PsychologistInitialInspectionForm;
