import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Col,
  Image,
  List,
  Pagination,
  Row,
  Segmented,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import { useNavigate } from "react-router-dom";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import admissionIcon from "../../assets/images/patientIcons/admission.svg";
import dischargeIcon from "../../assets/images/patientIcons/discharge.svg";
import dayjs from "dayjs";
import tagStyles from "../../styles/Custom/Tag.module.css";
import { getPatientListApi } from "../../services/patient.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import "./PatientList.css";

const { useToken } = theme;
const InPatientsCard = ({ userDetails, role }) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [activeInSegment, setActiveInSegment] = useState("current");
  const [patientList, setPatientList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const onInpatientSegmentChange = (value) => {
    setActiveInSegment(value);
  };
  const inPatientLabels = [
    { label: "Active", value: "current" },
    { label: "Discharged", value: "older" },
  ];
  useEffect(() => {
    getPatientListApi(
      `facility=${Number(getCookie(FacilityID)) || userDetails?.currFacility}`,
      activeInSegment,
      pageNo
    )
      .then((res) => {
        setPatientList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    getCookie(FacilityID) || userDetails?.currFacility,
    activeInSegment,
    pageNo,
  ]);

  return (
    <Row gutter={[0, 16]}>
      {/* <Col xs={0} lg={4}></Col> */}
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 16, offset: 4 }}
      >
        <List
          style={{ background: "#fff" }}
          className="patient-list"
          bordered
          pagination={false}
          //   pagination={{
          //     onChange: (page) => {
          //       console.log(page);
          //       setPageNo(page);
          //     },
          //     pageSize: 50,
          //     // style: {
          //     //   position: "fixed",
          //     // },
          //   }}
          header={
            <Row className="list-header">
              <Col span={12}>In Patients</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Segmented
                  options={inPatientLabels}
                  defaultValue={activeInSegment}
                  onChange={onInpatientSegmentChange}
                />
              </Col>
            </Row>
          }
          itemLayout="horizontal"
          size="small"
          dataSource={patientList?.data}
          renderItem={(item) => (
            <List.Item
              key={item?.title}
              extra={
                <Typography.Link
                  style={{ color: token.colorPrimary }}
                  onClick={() =>
                    navigate(`/${role}/home?patient=${item?.id}&type=landing`)
                  }
                >
                  View EMR <ArrowRightOutlined />
                </Typography.Link>
              }
            >
              <Col span={6}>
                <List.Item.Meta
                  avatar={
                    <CustomAvatar
                      name={`${item?.patient_name}`}
                      indexId={item?.id}
                      size="medium"
                    />
                  }
                  title={
                    <Space size="small">
                      <Typography>{item?.patient_name}</Typography>
                    </Space>
                  }
                  description={
                    <>
                      {(item?.patient_age || item?.patient_gender) && (
                        <Tag>
                          {item?.patient_age} {item?.patient_gender}
                        </Tag>
                      )}
                    </>
                  }
                  style={{ maxWidth: "200px" }}
                />
              </Col>

              <Tag>IP ID:{item?.ip_id}</Tag>
              <Col span={2}>
                {item?.bed_name && (
                  <Tag color="orange">
                    <Image src={bedImg} preview={false} /> {item?.bed_name}
                  </Tag>
                )}
              </Col>

              <Tag className={tagStyles.smTag}>
                <Image
                  src={
                    activeInSegment === "current"
                      ? admissionIcon
                      : dischargeIcon
                  }
                  style={{ height: "14px" }}
                />{" "}
                {item?.date_of_admission &&
                  dayjs(item?.date_of_admission).format("MMM DD")}
              </Tag>
              <Col span={4}>
                {item?.primary_consultant_name && (
                  <Tag>{item?.primary_consultant_name}</Tag>
                )}
              </Col>
            </List.Item>
          )}
        />
      </Col>
      {/* <Col xs={0} lg={4}></Col>
      <Col xs={0} lg={4}></Col> */}
      {/* <Col span={4}></Col> */}
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={{ span: 16, offset: 4 }}
        xl={{ span: 16, offset: 4 }}
        // xl={16}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Pagination
          current={pageNo}
          onChange={(value) => setPageNo(value)}
          pageSize={50}
          total={patientList?.total_pages * 50}
          showSizeChanger={false}
        />
      </Col>
    </Row>
  );
};

export default InPatientsCard;
