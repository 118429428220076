import { Col, Form, List, Radio, Row, Typography } from "antd";
import React from "react";
import { abstractionFields } from "../../../../Options/physioOptions";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Abstraction = () => {
  return (
    <>
      <List
        bordered
        style={{ width: "600px" }}
        dataSource={abstractionFields}
        renderItem={(field) => (
          <List.Item>
            <Row style={{ width: "100%" }} align="middle">
              <Col span={12}>
                <Typography>
                  <Typography.Text>{field.label}</Typography.Text>
                  <br />
                  <Typography.Text strong>{field.sequence}</Typography.Text>
                </Typography>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["abstraction_data", field.value]}
                  style={{ marginBottom: 0 }}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="space-around">
                      <Col>
                        <Radio.Button value={"yes"} className="like-btn">
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button value={"no"} className="dislike-btn">
                          <DislikeOutlined style={{ backgroundColor: "" }} />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default Abstraction;
