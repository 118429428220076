import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Drawer, Typography, Steps } from "antd";
import NurseInitialAssessmentFormProvider, {
  NurseInitialContext,
} from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import NurseInitialInspectionForm from "./NurseInitialInspectionForm";
import { getNurseInspectionAPI } from "../../../../services/nurse.services";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import NurseInitialInspectionSummary from "./NurseInitialInspectionSummary";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import "./NurseInitialInspection.css";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import assessmentIcon from "../../../../assets/images/nurseIcons/assessment.svg";

const NurseInitialInspection = ({ refreshPage, editId, patientData }) => {
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(NurseInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getNurseInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);
  return (
    <>
      {editId ? (
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              className="edit-btn"
              onClick={() => {
                setVisible(true);
                setAddModal(false);
              }}
            >
              Edit
              <EditOutlined />
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <NurseInitialInspectionSummary editId={editId} />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title={"No assessment has been done"}
              subtitle={
                "Kindly, fill the following form to continue initial assessment"
              }
              image={assessmentIcon}
            />
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(true);
              }}
              className={`${btnStyles.lgBtn} inv-btn`}
            >
              Initial Assessment Form
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        <Drawer
          open={visible}
          width="100%"
          title={
            <Row>
              <Col span={4} className="flex-start">
                <Typography
                  style={{
                    marginLeft: "12px",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}
                </Typography>
              </Col>

              <Col span={20} className="flex-end">
                <CloseOutlined onClick={() => setConfirmModal(true)} />
              </Col>
            </Row>
          }
          footer={null}
          onClose={() => setVisible(false)}
          closeIcon={null}
          keyboard={false}
        >
          <NurseInitialInspectionForm
            visible={visible}
            setVisible={setVisible}
            refreshPage={refreshPage}
            initialRefresh={setInitialAssessmentRefresh}
            editId={editId}
            patientData={patientData}
          />

          {confirmModal && (
            <ClosePopup
              open={confirmModal}
              setOpen={setConfirmModal}
              setVisible={setVisible}
            />
          )}
        </Drawer>
      )}
      {/* <NurseInitialInspectionForm /> */}
    </>
  );
};

export default NurseInitialInspection;
