import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Card,
  Button,
  Input,
  Form,
  message,
  Upload,
  Col,
  Row,
} from "antd";
import textStyles from "../../styles/Custom/Typography.module.css";
import inputStyles from "../../styles/Custom/Input.module.css";
import btnStyles from "../../styles/Custom/Button.module.css";
import styles from "../../styles/Custom/Dashboard/Users.module.css";
import { getCookie } from "../../helpers/localStorage";
import AccountTabs from "./AccountTabs";
import CommonLayout from "../Layout/CustomLayout";
import DoctorLayout from "../Layout/DoctorLayout/index";
import { UserContext } from "../../context/UserContext";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import { updateUserApi, userMetaDataApi } from "../../services/auth.services";
import { CloudUploadOutlined, FilePdfFilled } from "@ant-design/icons";
import {
  downloadUrlApi,
  getUploadUrlApi,
} from "../../services/common.services";
import { convertToFormData } from "../../helpers/utility";
import axios from "axios";

const { Dragger } = Upload;

const AccountDetails = ({ sideContent }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const exp = userDetails?.activeOrg?.experience;

  const getUserData = async () => {
    try {
      let resp = await userMetaDataApi();
      const orgId = getCookie("organisation_id");
      setUserDetails({
        ...resp?.data,
        profile_pic: resp?.data?.data?.profile_pic
          ? `${resp?.data?.data?.profile_pic}?dummy=${new Date().getTime()}`
          : null,
        activeOrg: orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {},
      });
      const experience = orgId
        ? resp?.data?.organizations?.filter((org) => org?.org_id == orgId)?.[0]
        : {};
      form.setFieldsValue({
        job_title: experience?.experience?.job_title,
        company_name: experience?.experience?.company_name,
        years: experience?.experience?.years,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    let payload = {
      experience: {
        ...values,
        certificates: uploadedFile ? uploadedFile : exp?.certificates,
      },
      user_id: userDetails?.id,
    };
    try {
      const resp = await updateUserApi(payload);
      if (resp) {
        setUpdateDetails(false);
        message.success(resp?.data?.message || "Details updated");
        getUserData();
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again"
      );
    }
  };
  async function uploadProfilePicFunc(info) {
    setLoading(true);
    if (info.file.status === "done") {
      try {
        const res = await getUploadUrlApi(`path_key=${info?.file?.name}`);
        const formData = convertToFormData(res, info);
        await axios({
          method: "POST",
          url: res?.data?.url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res?.data?.fields?.key) {
          updateProfile(res?.data?.fields?.key);
          setUploadedFile(res?.data?.fields?.key);
        }
      } catch (err) {
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  }
  const updateProfile = async (cert) => {
    const payload = {
      user_id: userDetails?.id,
      experience: {
        certificates: cert,
        job_title: exp?.job_title,
        company_name: exp?.company_name,
        years: exp?.years,
      },
    };
    try {
      const resp = await updateUserApi(payload);
      if (resp) {
        message.success(resp?.data?.message || "Details updated");
        getUserData();
        if (cert) {
          downloadPic(cert);
        }
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again"
      );
    }
  };
  const downloadPic = (profile) => {
    downloadUrlApi(`path_key=${profile}&used_for=certificate`)
      .then((res) => {
        setDocs(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  useEffect(() => {
    setUserDetails({ ...userDetails, activeApp: {} });
    downloadPic(exp?.certificates);
  }, []);
  const commonProfile = () => {
    return (
      <Card
        style={{ width: "50%", margin: "auto", padding: "20px" }}
        className={`${styles.personalDetailsCard} centeredCard`}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            job_title: exp?.job_title,
            company_name: exp?.company_name,
            years: exp?.years,
          }}
          onValuesChange={() => setUpdateDetails(true)}
          style={{ padding: "24px" }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Designation" name={"job_title"}>
                <Input
                  placeholder="Enter designation"
                  style={{ width: "100%" }}
                  className={inputStyles.mdInput}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              {/* Adjust span as needed */}
              <Form.Item label="Years of experience" name={"years"}>
                <Input
                  placeholder="Enter years of experience"
                  style={{ width: "100%" }}
                  className={inputStyles.mdInput}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Educational Qualification" name={"company_name"}>
            <Input
              style={{ width: "100%" }}
              placeholder="Enter educational qualification"
              className={inputStyles.mdInput}
            />
          </Form.Item>

          <Form.Item label="Upload Certificates" name={"certificates"}>
            {" "}
            <Dragger
              accept=".pdf"
              onChange={(info) => uploadProfilePicFunc(info)}
              multiple={false}
              showUploadList={false}
              customRequest={dummyRequest}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>

              <p className="ant-upload-text">
                <span style={{ color: "#406AFF" }}>Click to Upload </span> or
                drag and drop
              </p>
              <p className="ant-upload-hint">Pdf</p>
            </Dragger>
          </Form.Item>
        </Form>
        {docs && (
          <Card style={{ padding: "24px", margin: "0px 24px 12px 24px" }}>
            <a href={docs} target="_blank">
              <FilePdfFilled style={{ fontSize: "24px", color: "red" }} />{" "}
              {docs && "View Certificate"}
            </a>
          </Card>
        )}
      </Card>
    );
  };
  return (
    <>
      {userDetails?.activeOrg?.default_role === "Organization_Owner" ||
      userDetails?.activeOrg?.default_role === "Organization_Admin" ||
      userDetails?.activeOrg?.default_role === "Facility_Admin" ||
      userDetails?.activeOrg?.default_role === "Receptionist" ? (
        <CommonLayout
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>Account</Typography>
              <AccountTabs />
            </div>
          }
          sideContent={sideContent}
          footer={
            updateDetails && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdateDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonProfile()}
        </CommonLayout>
      ) : (
        <DoctorLayout
          header={
            <div
              style={{
                marginLeft: "20px",
                gap: "5%",
                width: "550px",
              }}
              className="flex-start"
            >
              <AccountTabs />
            </div>
          }
          sideContent={<SideMenu />}
          footer={
            updateDetails && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdateDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
          headerExtra={false}
        >
          {commonProfile()}
        </DoctorLayout>
      )}
    </>
  );
};

export default AccountDetails;
