import React, { useContext, useEffect, useState } from "react";
import { beckDepressionOptions } from "../../Options/psychologistOptions";
import { Form, Radio, List, Typography, Input } from "antd";

const BeckDepression = ({ context, form }) => {
  const { formData } = useContext(context);
  const [selectedValues, setSelectedValues] = useState({});

  const getValue = (pseudoValue) => {
    return pseudoValue.startsWith("1")
      ? 1
      : pseudoValue.startsWith("2")
      ? 2
      : 3;
  };

  const handleRadioChange = (name, pseudoValue) => {
    const value =
      typeof pseudoValue === "string" ? getValue(pseudoValue) : pseudoValue;
    setSelectedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateAggregate = () => {
    const sum = Object.values(selectedValues).reduce(
      (sum, value) => sum + value,
      0
    );
    form.setFieldsValue({ beck_depression: { aggregate: sum } });
    return sum;
  };

  const getDepressionLevelText = (aggregate) => {
    const interpretation =
      aggregate < 11
        ? "These ups and downs are considered normal"
        : aggregate < 17
        ? "Mild mood disturbance"
        : aggregate < 20
        ? "Borderline Depression"
        : aggregate < 31
        ? "Moderate Depression"
        : aggregate < 41
        ? "Severe Depression"
        : "Extreme Depression";
    form.setFieldsValue({
      beck_depression: { interpretation: interpretation },
    });
    return interpretation;
  };

  const aggregate = calculateAggregate();
  const depressionLevelText = getDepressionLevelText(aggregate);

  useEffect(() => {
    if (formData?.scales?.beck_depression) {
      Object.entries(formData?.scales?.beck_depression).forEach(
        ([key, value]) => {
          handleRadioChange(key, value);
        }
      );
    }
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>Beck Depression Inventory</Typography.Title>
      {beckDepressionOptions.map((option) => (
        <Form.Item
          key={option.value}
          label={option.label}
          name={["beck_depression", "scale", option.value]}
        >
          <Radio.Group
            options={option.options}
            onChange={(e) => handleRadioChange(option.value, e.target.value)}
          />
        </Form.Item>
      ))}

      <List bordered>
        <List.Item>
          <Typography.Text strong>
            Aggregate Score: {aggregate}{" "}
          </Typography.Text>
          <Form.Item name={["beck_depression", "aggregate"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
        <List.Item>
          <Typography.Text strong>
            Depression Level: {depressionLevelText}
          </Typography.Text>
          <Form.Item name={["beck_depression", "interpretation"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
      </List>
    </>
  );
};

export default BeckDepression;
