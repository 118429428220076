import React, { useContext, useEffect, useState } from "react";
import {
  beckAnxietyOptions,
  beckAnxietyScaleOptions,
} from "../../Options/psychologistOptions";
import { Form, Radio, List, Typography, Input } from "antd";

const BeckAnxiety = ({ context, form }) => {
  const { formData } = useContext(context);
  const [selectedValues, setSelectedValues] = useState({});

  const handleRadioChange = (name, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateAggregate = () => {
    const sum = Object.values(selectedValues).reduce(
      (sum, value) => sum + value,
      0
    );
    form.setFieldsValue({ beck_anxiety: { aggregate: sum } });
    return sum;
  };

  const getAnxietyLevelText = (aggregate) => {
    const interpretation =
      aggregate < 22
        ? "Low Anxiety"
        : aggregate < 36
        ? "Moderate Priority"
        : "Potentially concerning levels of anxiety";
    form.setFieldsValue({ beck_anxiety: { interpretation: interpretation } });
    return interpretation;
  };

  const aggregate = calculateAggregate();
  const anxietyLevelText = getAnxietyLevelText(aggregate);

  useEffect(() => {
    if (formData?.scales?.beck_anxiety) {
      Object.entries(formData?.scales?.beck_anxiety).forEach(([key, value]) => {
        handleRadioChange(key, value);
      });
    }
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>Beck Anxiety Inventory</Typography.Title>
      {beckAnxietyOptions.map((option) => (
        <Form.Item
          key={option.value}
          label={option.label}
          name={["beck_anxiety", "scale", option.value]}
        >
          <Radio.Group
            options={beckAnxietyScaleOptions}
            onChange={(e) => handleRadioChange(option.value, e.target.value)}
          />
        </Form.Item>
      ))}

      <List bordered>
        <List.Item>
          <Typography.Text strong>
            Aggregate Score: {aggregate}{" "}
          </Typography.Text>
          <Form.Item name={["beck_anxiety", "aggregate"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
        <List.Item>
          <Typography.Text strong>
            Anxiety Level: {anxietyLevelText}
          </Typography.Text>
          <Form.Item name={["beck_anxiety", "interpretation"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
      </List>
    </>
  );
};

export default BeckAnxiety;
