import React, { useState, useEffect } from "react";
import { Col, Radio, Row, DatePicker, Button, Space, Image } from "antd";
import GrbsMonitoring from "./GrbsMonitoring";
import NurseMonitoring from "./NurseMonitoring";
import dayjs from "dayjs";
import { getNurseMonitoringData } from "../../../../services/nurse.services";
import useQuery from "../../../../hooks/useQuery";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import VitalsModal from "./VitalsModal";
import GrbsModal from "./GrbsModal";
import { PlusOutlined } from "@ant-design/icons";
import calenderIcon from "../../../../assets/images/nurseIcons/calender.svg";

const dateFormat = "YYYY-MM-DD";
const options = [
  { title: "Vitals", value: "vitals" },
  { title: "GRBS", value: "grbs" },
];

const MonitoringTabs = () => {
  const params = useQuery();
  const curDate = dayjs().format(dateFormat);
  const [active, setActive] = useState("vitals");
  const [date, setDate] = useState(curDate);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [grbsOpen, setGrbsOpen] = useState(false);
  const [vitalsOpen, setVitalsOpen] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }

  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };

  useEffect(() => {
    const queryParam =
      active === "grbs" ? `?date=${date}&type=grbs` : `?date=${date}`;
    getNurseMonitoringData(params?.patient, queryParam)
      .then((res) => setData(res?.data?.data))
      .catch((err) => console.log(err));
  }, [refresh, params?.patient, date, active]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={18}>
        <Radio.Group
          onChange={(e) => setActive(e.target.value)}
          buttonStyle="solid"
          defaultValue={active}
        >
          {options?.map((item) => (
            <Radio.Button key={item?.value} value={item?.value}>
              {item?.title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Col>
      <Col span={6} className="flex-end">
        <Space>
          <DatePicker
            style={{ border: "1px solid #a182e3" }}
            disabledDate={disabledDate}
            className={inputStyles.mdDateInput}
            onChange={onDateChange}
            defaultValue={dayjs(curDate)}
            allowClear={false}
            suffixIcon={<Image width={20} src={calenderIcon} preview={false} />}
          />
          <Button
            style={{ height: "36px" }}
            type="primary"
            className={`{btnStyles.mdBtn} inv-btn`}
            onClick={() => {
              active === "vitals" ? setVitalsOpen(true) : setGrbsOpen(true);
            }}
          >
            <PlusOutlined /> SOS
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        {active === "vitals" ? (
          <NurseMonitoring data={data} refresh={refreshPage} />
        ) : (
          <GrbsMonitoring data={data} refresh={refreshPage} />
        )}
      </Col>
      {vitalsOpen && (
        <VitalsModal
          open={vitalsOpen}
          setOpen={setVitalsOpen}
          refresh={refreshPage}
        />
      )}
      {grbsOpen && (
        <GrbsModal
          open={grbsOpen}
          setOpen={setGrbsOpen}
          refresh={refreshPage}
        />
      )}
    </Row>
  );
};

export default MonitoringTabs;
