import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Card,
  Button,
  Input,
  Form,
  Image,
  message,
  Upload,
  Avatar,
  Spin,
  Row,
  Col,
  Tooltip,
} from "antd";
import textStyles from "../../styles/Custom/Typography.module.css";
import inputStyles from "../../styles/Custom/Input.module.css";
import btnStyles from "../../styles/Custom/Button.module.css";
import styles from "../../styles/Custom/Dashboard/Users.module.css";
import { getCookie, setItem } from "../../helpers/localStorage";
import {
  colors,
  capitalizeFirstLetter,
  convertToFormData,
} from "../../helpers/utility";
import AccountTabs from "./AccountTabs";
import CommonLayout from "../Layout/CustomLayout";
import DoctorLayout from "../Layout/DoctorLayout/index";
import { UserContext } from "../../context/UserContext";
import { EditOutlined } from "@ant-design/icons";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import { updateUserApi, userMetaDataApi } from "../../services/auth.services";
import {
  downloadUrlApi,
  getUploadUrlApi,
} from "../../services/common.services";
import axios from "axios";

const AccountDetails = ({ sideContent }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [displayPic, setDisplayPic] = useState(null);
  const numericUserId = Number(userDetails?.id);
  const colorIndex = numericUserId % 24;
  const selectedColor = colors[colorIndex];
  const profilePic = userDetails?.activeOrg?.usr_profile_pic;

  const getUserData = async () => {
    try {
      let resp = await userMetaDataApi();
      const orgId = getCookie("organisation_id");
      const profile = orgId
        ? resp?.data?.organizations?.filter((org) => org?.org_id == orgId)?.[0]
        : {};
      setUserDetails({
        ...resp?.data,
        profile_pic: profile?.usr_profile_pic
          ? `${profile?.usr_profile_pic}`
          : null,
        activeOrg: orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {},
      });
      form.setFieldsValue({
        first_name: resp?.data?.first_name,
        last_name: resp?.data?.last_name,
        email: resp?.data?.email,
        contact_number: resp?.data?.mobile,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfile = async (profile) => {
    const payload = { user_id: userDetails?.id, profile_pic: profile };
    try {
      const resp = await updateUserApi(payload);
      if (resp) {
        message.success(resp?.data?.message || "Details updated");
        getUserData();
        if (profile) {
          downloadPic(profile);
        }
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again"
      );
    }
  };

  const onFinish = async (values) => {
    let payload = {
      ...values,
      first_name: values?.first_name?.trim(),
      last_name: values?.last_name?.trim(),
      user_id: userDetails?.id,
    };
    try {
      const resp = await updateUserApi(payload);
      if (resp) {
        setUpdateDetails(false);
        message.success(resp?.data?.message || "Details updated");
        getUserData();
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again"
      );
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  async function uploadProfilePicFunc(info) {
    setLoading(true);
    if (info.file.status === "done") {
      try {
        const res = await getUploadUrlApi(
          `path_key=${info?.file?.name}&used_for=profile_pic`
        );
        const formData = convertToFormData(res, info);
        await axios({
          method: "POST",
          url: res?.data?.url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res?.data?.fields?.key) {
          updateProfile(res?.data?.fields?.key);
        }
      } catch (err) {
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  }

  const downloadPic = (profile) => {
    downloadUrlApi(`path_key=${profile}&used_for=profile_pic`)
      .then((res) => {
        setDisplayPic(res?.data);
        setItem(
          "profile_pic",
          JSON.stringify({
            path: res?.data,
            key: profile,
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setUserDetails({ ...userDetails, activeApp: {} });
    setDisplayPic(userDetails?.activeOrg?.usr_profile_pic);
    // downloadPic(profilePic);
  }, []);

  const commonProfile = () => {
    return (
      <Card
        style={{ width: "50%", margin: "auto" }}
        className={`${styles.personalDetailsCard} centeredCard`}
      >
        <div className={styles.profileBanner}></div>
        <Upload
          listType="picture"
          onChange={(info) => uploadProfilePicFunc(info)}
          multiple={false}
          showUploadList={false}
          customRequest={dummyRequest}
          accept="image/*"
        >
          <div className={styles.profileAvatar} style={{ cursor: "pointer" }}>
            <div className={styles.profilePicOutline}></div>
            {displayPic ? (
              loading ? (
                <Spin />
              ) : (
                <Image
                  preview={false}
                  src={displayPic}
                  alt={"Profile Pic"}
                  width={130}
                  height={130}
                  style={{
                    height: "130px",
                    width: "130px",
                    borderRadius: "100%",
                  }}
                />
              )
            ) : (
              <Avatar
                size={130}
                style={{
                  fontSize: "70px",
                  background: selectedColor,
                }}
              >
                {userDetails?.first_name?.slice(0, 1).toUpperCase()}
                {userDetails?.last_name?.slice(0, 1)?.toUpperCase()}
              </Avatar>
            )}
            <div
              style={{
                backgroundColor: "#667085",
                padding: "8px",
                borderRadius: "100%",
                position: "absolute",
                top: "100px",
                left: "100px",
              }}
              className="flex-center"
            >
              <Tooltip title="Change profile picture">
                <EditOutlined style={{ cursor: "pointer" }} />
              </Tooltip>
            </div>
          </div>
        </Upload>
        <Typography
          className={textStyles.h5}
          style={{ marginLeft: "200px", fontWeight: "600" }}
        >
          {capitalizeFirstLetter(userDetails.first_name)}{" "}
          {capitalizeFirstLetter(userDetails.last_name)}
        </Typography>
        <Typography
          className={textStyles.body3}
          style={{ color: "#475467", marginLeft: "204px" }}
        >
          {userDetails.email}
        </Typography>
        <Form
          layout="vertical"
          form={form}
          // requiredMark={false}
          initialValues={{
            first_name: userDetails?.first_name,
            last_name: userDetails.last_name,
            email: userDetails.email,
            mobile: userDetails?.mobile,
          }}
          onValuesChange={() => setUpdateDetails(true)}
          style={{ padding: "24px" }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First name"
                name={"first_name"}
                rules={[
                  {
                    max: 20,
                    message: "First name cannot exceed 20 characters",
                  },
                  {
                    min: 2,
                    message: "First name should be at least 2 characters long",
                  },
                  {
                    pattern: /^[a-zA-Z0-9\s\-_]+$/,
                    message:
                      "First name should contain only alphabets, numbers, spaces, hyphens, and underscores",
                  },
                  {
                    required: true,
                    message: "Please provide your first name",
                  },
                  {
                    whitespace: true,
                    message: "Please provide your first name",
                  },
                ]}
              >
                <Input
                  placeholder="Enter First name"
                  style={{ width: "100%" }}
                  className={inputStyles.mdInput}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last name"
                name={"last_name"}
                rules={[
                  {
                    max: 20,
                    message: "Last name cannot exceed 20 characters",
                  },
                  {
                    min: 2,
                    message: "Last name should be at least 2 characters long",
                  },
                  {
                    pattern: /^[a-zA-Z0-9\s\-_]+$/,
                    message:
                      "Last name should contain only alphabets, numbers, spaces, hyphens, and underscores",
                  },
                  {
                    required: true,
                    message: "Please provide your last name",
                  },
                  {
                    whitespace: true,
                    message: "Please provide your last name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Enter Last name"
                  className={inputStyles.mdInput}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name={"email"}
                rules={[
                  {
                    type: "email",
                    message: "Enter valid email",
                  },

                  {
                    whitespace: true,
                    message: "Please provide your last name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Enter Last name"
                  className={inputStyles.mdInput}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact number"
                name={"mobile"}
                rules={[
                  {
                    validator: (_, value, callback) => {
                      if (value && value !== "") {
                        if (/^[0-9]{10}$/.test(value)) {
                          callback();
                        } else {
                          callback(
                            "Please enter a valid 10 digit phone number"
                          );
                        }
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  addonBefore={"+91"}
                  placeholder="Enter Contact number"
                  className={inputStyles.mdAddonInput}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  };

  return (
    <>
      {userDetails?.activeOrg?.default_role === "Organization_Owner" ||
      userDetails?.activeOrg?.default_role === "Organization_Admin" ||
      userDetails?.activeOrg?.default_role === "Facility_Admin" ||
      userDetails?.activeOrg?.default_role === "Receptionist" ? (
        <CommonLayout
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>Account</Typography>
              <AccountTabs />
            </div>
          }
          sideContent={sideContent}
          footer={
            updateDetails && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdateDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonProfile()}
        </CommonLayout>
      ) : (
        <DoctorLayout
          header={
            <div
              style={{
                marginLeft: "20px",
                gap: "5%",
                width: "550px",
              }}
              className="flex-start"
            >
              {/* <Typography className={textStyles.h6}>Account</Typography> */}
              <AccountTabs />
            </div>
          }
          footer={
            updateDetails && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdateDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
          headerExtra={false}
        >
          {commonProfile()}
        </DoctorLayout>
      )}
    </>
  );
};

export default AccountDetails;
