import React, { useState } from "react";
import {
  Table,
  Tag,
  Col,
  Pagination,
  Space,
  message,
  Popconfirm,
  Button,
} from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import EditAppointmentModal from "./EditAppointmentModal";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import { capitalizeFirstLetter } from "../../../helpers/auth/helper";
import { updateAppointmentApi } from "../../../services/receptionist.services";
import dayjs from "dayjs";

const AppointmentsTable = ({ data, refresh, page, setPage, loading }) => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const onCancel = (id) => {
    updateAppointmentApi({ status: "cancelled", appointment_id: id })
      .then((res) => {
        message.success(res?.data?.message);
        refresh();
      })
      .catch((err) => message.error(err?.response?.data?.message));
  };

  const columns = [
    {
      title: "Name of Patient",
      dataIndex: "patient_first_name",
      key: "patient_first_name",
      render: (value, record) =>
        `${capitalizeFirstLetter(value)} ${capitalizeFirstLetter(
          record.patient_last_name
        )}`,
    },

    {
      title: "Date",
      dataIndex: "appointment_date",
      key: "appointment_date",
      render: (value) => dayjs(value).format("DD MMM"),
    },
    {
      title: "Slot",
      dataIndex: "appointment_time",
      key: "appointment_time",
      render: (value) =>
        dayjs(value?.split(":").slice(0, 2).join(":"), "HH:mm")?.format(
          "hh:mm A"
        ),
    },
    {
      title: "Practitioner Name",
      dataIndex: "doctor_name",
      key: "doctor_name",
      render: (value) =>
        `${capitalizeFirstLetter(value.split(" ")[0])} ${capitalizeFirstLetter(
          value.split(" ")[1]
        )}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Tag
          color={
            value === "reported"
              ? "orange"
              : value === "scheduled"
              ? "green"
              : "magenta"
          }
          className={tagStyles.mdRndTag}
        >
          {capitalizeFirstLetter(value)}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          {record?.status !== "cancelled" && (
            <>
              <Button
                onClick={() => {
                  setOpen(true);
                  setEditData(record);
                }}
              >
                <EditOutlined />
              </Button>
              {!dayjs(record?.appointment_date).isBefore(dayjs(), "day") && (
                <Popconfirm
                  title="Sure to cancel appointment?"
                  okText="Yes"
                  cancelText="No"
                  icon={null}
                  onConfirm={() => onCancel(record?.id)}
                >
                  <Button>
                    <CloseOutlined style={{ color: "red" }} />
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={loading}
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: 0 }}
      />
      {data?.data?.length > 50 && (
        <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={50}
            total={data?.total_pages}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </Col>
      )}

      {open && (
        <EditAppointmentModal
          open={open}
          setOpen={setOpen}
          editData={editData}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default AppointmentsTable;
