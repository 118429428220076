import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  Divider,
  Image,
  Space,
  Typography,
  Card,
  Row,
  Col,
  Button,
} from "antd";
import { ArrowLeftOutlined, LinkOutlined } from "@ant-design/icons";
import UploadsView from "./UploadsView";
import GridPdfViewer from "./GridPdfViewer";
import PdfViewer from "./PdfViewer";
import "slick-carousel/slick/slick-theme.css";
import "./AllImageView.css";
import btnStyles from "../../../../styles/Custom/Button.module.css";

const { Meta } = Card;
const ImageMasonry = ({ data, onGroupSelect, setOpenPdf, setPdfData }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    const groupedItems = {};
    if (data) {
      data.forEach((item) => {
        item?.report_path?.forEach((urlItem) => {
          const name = item?.name || item?.investigation_name;

          if (!groupedItems[name]) {
            groupedItems[name] = [];
          }

          if (urlItem?.type?.includes("image")) {
            groupedItems[name].push({
              type: "image",
              url: urlItem.report_url,
              path: urlItem?.path?.replace("curengo/", ""),
            });
          } else {
            groupedItems[name].push({
              type: "pdf",
              url: urlItem.report_url,
              path: urlItem?.path?.replace("curengo/", ""),
            });
          }
        });
      });
    }

    const groupedArray = Object.keys(groupedItems).map((name) => ({
      name,
      items: groupedItems[name],
    }));

    setItems(groupedArray);
  }, [data]);

  items?.forEach((item, index) => {
    console.log(`Item ${index + 1}:`, item);
  });

  return (
    <div className="all-img-container">
      {items?.map((group) => (
        <div key={group?.name}>
          <Typography.Link
            style={{ color: "#7244d5" }}
            className="inv-name"
            onClick={() => {
              onGroupSelect(group);
            }}
          >
            <LinkOutlined /> {group?.name}
          </Typography.Link>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 300: 3, 500: 4, 700: 3, 900: 5 }}
          >
            <div className="img-container">
              <Row gutter={[16, 16]}>
                {group?.items.map((item, index) => (
                  <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div
                      className="masonry-item"
                      onClick={() => {
                        if (item.type === "pdf") {
                          setOpenPdf(true);
                          setPdfData(item);
                        }
                      }}
                    >
                      {item.type === "image" ? (
                        <Card
                          className="img-card"
                          hoverable
                          cover={
                            <Image
                              alt="example"
                              style={{ width: "250px", height: "150px" }}
                              src={item?.url}
                            />
                          }
                        >
                          <Meta
                            title={
                              <div className="meta-title">
                                <Typography>{item?.path}</Typography>
                              </div>
                            }
                          />
                        </Card>
                      ) : (
                        <GridPdfViewer
                          className="img-card"
                          data={[item]}
                          pageNumber={1}
                          height="150px"
                          width="250px"
                        />
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </ResponsiveMasonry>
          <Divider />
        </div>
      ))}
    </div>
  );
};

const MasonryImg = ({ data }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfData, setPdfData] = useState([]);

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
  };

  return (
    <div>
      {selectedGroup ? (
        <>
          <div className="sel-group-container">
            <Button
              type="primary"
              style={{ backgroundColor: "blueviolet", color: "white" }}
              ghost
              className={btnStyles.mdBtn}
              onClick={() => setSelectedGroup(null)}
            >
              <ArrowLeftOutlined />
              Back
            </Button>
            <UploadsView selectedGroup={selectedGroup} />
          </div>
        </>
      ) : openPdf ? (
        <>
          <Space>
            <ArrowLeftOutlined onClick={() => setOpenPdf(false)} />
            <Typography
              type="primary"
              style={{ backgroundColor: "blueviolet", color: "white" }}
              ghost
              className={btnStyles.mdBtn}
              onClick={() => setSelectedGroup(null)}
            >
              Back
            </Typography>
          </Space>
          <PdfViewer pdfData={[pdfData]} />
        </>
      ) : (
        <ImageMasonry
          data={data}
          onGroupSelect={handleGroupSelect}
          setOpenPdf={setOpenPdf}
          setPdfData={setPdfData}
        />
      )}
    </div>
  );
};

export default MasonryImg;
