import { Descriptions } from "antd";
import React from "react";
import {
  getDepressionScaleTags,
  getLabelScalesTags,
  getLabelTagInput,
} from "../constructTextFromValues";
import {
  beckAnxietyOptions,
  beckDepressionOptions,
  emotionalIntelligenceOptions,
} from "../../Options/psychologistOptions";

const PsychologistScalesReviewSummary = ({ formData }) => {
  const constructScalesSummaryItems = () => {
    const scalesSummaryItems = [];
    const scales = formData?.scales || {};
    const pushScalesItem = (key, label, children) => {
      scalesSummaryItems.push({ key, label, children });
    };
    const checkEmpty = (data) => {
      return Object.keys(data).length !== 0;
    };
    if (checkEmpty(scales?.emotional_intelligence)) {
      pushScalesItem(
        "1",
        "Emotional Intelligence Scale",
        <>
          {getLabelTagInput(
            "Self Awareness",
            scales?.emotional_intelligence?.self_awareness
          )}
          {getLabelTagInput(
            "Self Management",
            scales?.emotional_intelligence?.self_management
          )}
          {getLabelTagInput(
            "Social Awareness",
            scales?.emotional_intelligence?.social_awareness
          )}
          {getLabelTagInput(
            "Relationship Management",
            scales?.emotional_intelligence?.relationship_management
          )}
          {getLabelTagInput(
            "Emotional Intelligence",
            scales?.emotional_intelligence?.interpretation
          )}
          {/* {getLabelScalesTags(
            emotionalIntelligenceOptions,
            scales?.emotional_intelligence?.scale
          )} */}
        </>
      );
    }
    if (checkEmpty(scales?.beck_anxiety)) {
      pushScalesItem(
        "2",
        "Beck Anxiety Inventory",
        <>
          {getLabelTagInput(
            "Beck Anxiety Inventory",
            scales?.beck_anxiety?.aggregate
          )}
          {getLabelTagInput(
            "Anxiety Level",
            scales?.beck_anxiety?.interpretation
          )}
          {/* {getLabelScalesTags(beckAnxietyOptions, scales?.beck_anxiety)} */}
        </>
      );
    }
    if (checkEmpty(scales?.beck_depression)) {
      pushScalesItem(
        "3",
        "Beck Depression Inventory",
        <>
          {getLabelTagInput(
            "Beck Depression Inventory",
            scales?.beck_depression?.aggregate
          )}
          {getLabelTagInput(
            "Depression Level",
            scales?.beck_depression?.interpretation
          )}
          {/* {getDepressionScaleTags(
            beckDepressionOptions,
            scales?.beck_depression
          )} */}
        </>
      );
    }
    return scalesSummaryItems;
  };
  const scalesSummaryItems = constructScalesSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Scales"}
        items={scalesSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologistScalesReviewSummary;
