import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Typography, Card } from "antd";
import "./PhysioScales.css";
import Gcs from "../GeneralExaminationForm/GeneralExaminationComponents/Gcs";
import {
  gcScaleAPI,
  getGcScaleAPI,
  updateGcScaleApi,
} from "../../../../../services/physio.services";

const GCScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  let formData = {};
  const [states, setStates] = useState({});

  const handleChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      score: aggregate,
      interpretation: interpretation,
      ip_admission: admission,
    };

    try {
      if (scalesId?.gcs) {
        await updateGcScaleApi(scalesId?.gcs, finalData);
      } else {
        const response = await gcScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, gcs: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.gcs) {
      getGcScaleAPI(admission, scalesId?.gcs)
        .then((response) => {
          formData = response.data;
          form.setFieldsValue(formData);
          setStates(
            Object.fromEntries(
              Object.entries(formData).filter(([key]) =>
                [
                  "motor_response",
                  "verbal_response",
                  "eye_opening_response",
                ].includes(key)
              )
            )
          );
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const aggregate = Object.values(states).reduce((acc, cur) => acc + cur, 0);
  const interpretation =
    aggregate < 9
      ? "Severe Brain Injury"
      : aggregate < 13
      ? "Moderate Brain Injury"
      : aggregate < 15
      ? "Mild Brain Injury"
      : "Normal";
  return (
    <>
      <div className="cardless-scale">
        <Typography className="score-counter">
          {aggregate}
          <br />—{/* <div className="line" /> */}
          <br />
          15
          <span className="score-string">{interpretation}</span>
        </Typography>
        <Card style={{ padding: "20px", width: "800px" }}>
          <Form
            id="gcs-scale"
            layout="vertical"
            colon={false}
            onFinish={(values) => handleSubmitWithAction(values, "continue")}
            form={form}
          >
            <Gcs handleChange={handleChange} />
            <br />
            <Form.Item className="scales-comments-formitem" name="comments">
              <Input.TextArea
                placeholder="Comments"
                rows={4}
                style={{ width: "100%", marginTop: "12px" }}
              />
            </Form.Item>
          </Form>
        </Card>
      </div>

      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() => {
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"));
          }}
        >
          Close
        </Button>

        <Button
          size="large"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default GCScale;
