import React from "react";
import { Form, Slider, Typography } from "antd";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
const Gcs = ({ handleChange }) => {
  return (
    <>
      {/* <Typography>Eye Opening Response</Typography> */}

      <Form.Item label="Eye Opening Response" name="eye_opening_response">
        <Slider
          marks={{ 0: "NT", 1: "", 2: "", 3: "", 4: "4" }}
          // tooltip={{
          //   color: "#7f56d9",
          //   open: true,

          // }}
          step={1}
          defaultValue={0}
          max={4}
          min={0}
          style={{ width: "90%" }}
          onChange={(value) => handleChange("eye_opening_response", value)}
          className="selection-slider"
        />
      </Form.Item>
      <br />

      {/* <Typography>Verbal Response</Typography> */}
      <Form.Item label="Verbal Response" name="verbal_response">
        <Slider
          marks={{ 0: "NT", 1: "", 2: "", 3: "", 4: "", 5: "5" }}
          step={1}
          defaultValue={0}
          max={5}
          min={0}
          style={{ width: "90%" }}
          onChange={(value) => handleChange("verbal_response", value)}
          // onChangeComplete={}
          className="selection-slider"
        />
      </Form.Item>
      <br />

      {/* <Typography>Motor Response</Typography> */}

      <Form.Item label="Motor Response" name="motor_response">
        <Slider
          marks={{ 0: "NT", 1: "", 2: "", 3: "", 4: "", 5: "", 6: "6" }}
          step={1}
          defaultValue={0}
          max={6}
          min={0}
          style={{ width: "90%" }}
          onChange={(value) => handleChange("motor_response", value)}
          className="selection-slider"
        />
      </Form.Item>
    </>
  );
};

export default Gcs;
