import { ACCESS_TOKEN, USER } from "../constants/defaultKeys";
import { downloadUrlApi } from "../services/common.services";
import {
  clear,
  extractDomainFromUrl,
  getCookie,
  getItem,
} from "./localStorage";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export const isAuthenticated = () => {
  let isTokenAvailable = false;
  const access_token = getCookie(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
};
export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};
export const getUser = () => {
  if (typeof localStorage !== "undefined") {
    return JSON.parse(localStorage.getItem(USER));
  } else {
    return null;
  }
};
export const getAvatarBackgroundColor = (name) => {
  if (!name) return colors[0]; // Default color if name is empty
  const charIndex = name.charCodeAt(0) % colors.length;
  return colors[charIndex];
};
export const colors = [
  "#8f2d56ff",
  "#83e377",
  "#2c699a",
  "#048ba8",
  "#0db39e",
  "#54478c",
  "#d81159ff",
  "#cc4400",
  "#000077",
  "#b9e769",
  "#efea5a",
  "#f1c453",
  "#f29e4c",
  "#d66915",
  "#e08e29",
  "#f0c761",
  "#7cc6de",
  "#3890bc",
  "#be1e2dff",
  "#1c489a",
  "#006ba6ff",
  "#471ca8ff",
  "#884ab2ff",
  "#72bad5ff",
  "#03324eff",
  "#ef4043ff",
  "#16db93",
  "#c43240ff",
  "#52ace4",
];
export const capitalizeFirstLetter = (word) => {
  if (typeof word !== "string" || word.length === 0) {
    return word; // Return the original word if it's not a string or is empty
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getRandomColor = (valueName) => {
  const colorNames = [
    "magenta",
    "cyan",
    "purple",
    "geekblue",
    "gold",
    "blue",
    "lime",
    "volcano",
  ];
  let hash = 0;
  for (let i = 0; i < valueName?.length; i++) {
    hash = valueName?.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % colorNames.length;
  return colorNames[index];
};
export const userRoles = [
  { label: "Organization Owner", value: "Organization_Owner", hod: false },
  { label: "Organization Admin", value: "Organization_Admin", hod: false },
  { label: "Facility Admin", value: "Facility_Admin", hod: false },
  { label: "Doctor", value: "Doctor", hod: true },
  { label: "Nurse", value: "Nurse", hod: true },
  { label: "Physiotherapist", value: "Physiotherapist", hod: true },
  {
    label: "Speech and Swallow Therapist",
    value: "Speech_Swallow_Therapist",
    hod: true,
  },
  { label: "Respiratory Therapist", value: "Respiratory_Therapist", hod: true },

  { label: "Nutritionist", value: "Nutritionist", hod: true },
  { label: "Receptionist", value: "Receptionist", hod: true },
  { label: "Psychologist", value: "Psychologist", hod: true },
];

export function debounce(func, delay = 300) {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
}

export const convertToFormData = (res, info) => {
  console.log(res, info);
  const formData = new FormData();
  for (var key in res?.data?.fields) {
    formData.append(key, res.data.fields[key]);
  }
  formData.append("file", info.file.originFileObj);

  return formData;
};
export const orgColors = [
  "#B45ED0",
  "#7d2cb0",
  "#4F70D1",
  "#3869D4",
  "#1C74D4",
  "#006DCF",
  "#2A5FD9",
  "#0060E0",
  "#006BCE",
  "#00767A",
  "#624ED9",
  "#f26e21",
  "#bd5519",
];

export async function handleDownload(path) {
  try {
    const url = await downloadUrlApi(`path_key=${path}`);
    console.log("Download URL:", url);
  } catch (error) {
    console.error("Error:", error);
  }
}
export const rgbaToHex = (rgba) => {
  const parts = rgba.match(/\d+/g);
  const r = parseInt(parts[0], 10).toString(16).padStart(2, "0");
  const g = parseInt(parts[1], 10).toString(16).padStart(2, "0");
  const b = parseInt(parts[2], 10).toString(16).padStart(2, "0");
  const a = Math.round(parseFloat(parts[3]) * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${r}${g}${b}${a}`;
};
export const investigationSteps = [
  "Download the Master Investigations List",
  "Edit the Name and Price columns as per your Organization",
  "Select the DEPARTMENT field from the dropdown options provided",
  "Upload the excel in Add Investigations Section",
];
export const handleMasterDownload = () => {
  // console.log(extractDomainFromUrl(window.location.href));
  const link = document.createElement("a");
  link.download = "investigations.xlsx";

  link.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/investigations.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const handleSampleDownload = () => {
  const link = document.createElement("a");
  link.download = "sample.xlsx";

  link.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/sample.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export function timeAgo(utcTimestamp) {
  const currentDate = new Date();
  const pastDate = new Date(utcTimestamp);

  const timeDifference = currentDate.getTime() - pastDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days === 1 ? `${days} day ago` : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? `${hours} hr ago` : `${hours} hrs ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? `${minutes} min ago` : `${minutes} mins ago`;
  } else {
    return "Just now";
  }
}
export function daysPassed(utcTimestamp) {
  const currentDate = new Date();
  const pastDate = new Date(utcTimestamp);

  const timeDifference = currentDate.getTime() - pastDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days === 1 ? `${days} day` : `${days} days`;
  } else if (hours > 0) {
    return hours === 1 ? `${hours} hr` : `${hours} hrs`;
  } else if (minutes > 0) {
    return minutes === 1 ? `${minutes} min` : `${minutes} mins`;
  } else {
    return "Just now";
  }
}
export const rolesInfo = {
  Doctor: {
    href: "/doctor/home",
  },
  Physiotherapist: {
    href: "/physiotherapist/home",
  },
  Nutritionist: {
    href: "/nutritionist/home",
  },
  Speech_Swallow_Therapist: {
    href: "/speech-swallow-therapist/home",
  },
  Respiratory_Therapist: {
    href: "/respiratory-therapist/home",
  },
  Psychologist: {
    href: "/psychologist/home",
  },
  Nurse: {
    href: "/nurse/home",
  },
  Organization_Admin: {
    href: "/dashboard/home",
  },
  Organization_Owner: {
    href: "/dashboard/home",
  },
  Facility_Admin: {
    href: "/dashboard/home",
  },
  Receptionist: {
    href: "/receptionist/home",
  },
};
dayjs.extend(utc);
dayjs.extend(timezone);

export function convertUtcToIst(utcTimestamp, date = true) {
  if (!utcTimestamp) {
    return null;
  }

  const istDateString = date
    ? dayjs.utc(utcTimestamp).format("DD MMM, hh:mm A")
    : dayjs.utc(utcTimestamp).format("hh A");

  return istDateString;
}
export function formatToINR(amount) {
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(amount || 0);

  // Add space between rupee symbol and value
  return formattedAmount.replace("₹", " ₹ ");
}
